import * as Yup from 'yup';
import field from './status'

const form = (faculty, teachers) => [
  {
    name: 'id',
    type: 'hidden',
    initial: faculty.id || 0,
  },
  {
    name: 'academy_id',
    type: 'hidden',
    initial: faculty.academy_id || '',
  },
  {
    name: 'name',
    type: 'string',
    label: 'Nome',
    validation: Yup.string().required(),
    initial: faculty.name || '',
  },
  {
    name: 'description',
    type: 'text',
    label: 'Descrizione',
    validation: Yup.string().required(),
    initial: faculty.description || '',
  },
  {
    name: 'reason',
    type: 'text',
    label: 'Motivazione',
    validation: Yup.string().required(),
    initial: faculty.reason || '',
  },
  {
    name: 'audience',
    type: 'text',
    label: 'Audience',
    validation: Yup.string().required(),
    initial: faculty.audience || '',
  },
  {
    name: 'logo',
    type: 'file',
    label: 'Immagine logo',
    initial: faculty.logo,
  },
  {
    name: 'preview',
    type: 'file',
    label: 'Immagine preview',
    initial: faculty.preview,
  },
  {
    name: 'dean_id',
    type: 'parent',
    label: 'Preside',
    initial: {id: faculty.dean_id} || {},
    options: teachers,
  },
  field(faculty),
];

export default form
