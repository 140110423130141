import * as Yup from 'yup';
import field from './status';

const form = (article) => [
  {
    name: 'id',
    type: 'hidden',
    initial: article.id || 0,
  },
  {
    name: 'faculty_id',
    type: 'hidden',
    initial: article.faculty_id || '',
  },
  {
    name: 'title',
    type: 'string',
    label: 'Titolo',
    validation: Yup.string().required(),
    initial: article.title || '',
  },
  {
    name: 'audio',
    type: 'text',
    label: 'Audio link',
    validation: Yup.string(),
    initial: article.audio || '',
  },
  {
    name: 'body',
    type: 'text',
    label: 'Body html',
    validation: Yup.string(),
    initial: article.body || '',
  },
  {
    name: 'cover',
    type: 'file',
    label: 'Immagine di copertina',
    initial: article.cover,
  },
  field(article),
];

export default form
