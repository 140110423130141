import React, { Component } from 'react';
import { Button } from '@material-ui/core';

import AssociationDialog from './AssociationDialog'

const buttonStyle = {
  backgroundColor: '#f0f0f0',
  marginLeft: '10px',
  boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
}

class ParentField extends Component {
  state = {
    modal: false,
  }

  showModal = () => this.setState({ modal: true })

  hideModal = () => this.setState({ modal: false })

  render() {
    const {title, all, current} = this.props
    const {add} = this.props
    let currentItem = {}
    if (current.id){
      currentItem = all.filter(el => el.value === current.id)[0]
    }
    
    return (
      <>
        <div>
          <span>{title} attuale: </span>
          {currentItem.label ? currentItem.label : '---'}
          <Button onClick={this.showModal} style={buttonStyle}>
            Scegli
          </Button>
        </div>
        <AssociationDialog
          title={title}
          open={this.state.modal}
          all={all}
          current={[current]}
          success={(assoc) => {
            add(assoc)
            this.hideModal()
          }}
          dismiss={this.hideModal}
        />
      </>
    )
  }
}

export default ParentField
