import * as Yup from 'yup';

const form = (topic) => [
  {
    name: 'id',
    type: 'hidden',
    initial: topic.id || 0,
  },
  {
    name: 'course_id',
    type: 'hidden',
    initial: topic.course_id || '',
  },
  {
    name: 'name',
    type: 'string',
    label: 'Nome',
    validation: Yup.string().required(),
    initial: topic.name || '',
  },
  {
    name: 'description',
    type: 'text',
    label: 'Descrizione',
    validation: Yup.string().required(),
    initial: topic.description || '',
  },
];

export default form
