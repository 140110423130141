import React from 'react';
import { sortBy } from 'lodash';
import EntityForm, {defaultSchema, validationSchema, typeMapping} from './EntityForm';

import { clientInstance as client } from '../../api/upcademy.js'
import { Button } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { isEmpty, cloneDeep } from 'lodash';
import { FormikTextField, FormikSelectField, FormikRadioGroupField } from 'formik-material-fields';
import FormikDateTime from '../FormikDateTime/FormikDateTime';

import UploadDialog from '../UploadDialog/UploadDialog';
import ParentField from '../AssociationEditor/ParentField';

import style from '../EntityForm/EntityForm.module.css';

class PillEntityForm extends EntityForm{

  loadThumb = (values, setFieldValue) => {
    if (values.video === '') return

    client.getVimeoInfo(values.video)
      .then(r => {
        const {data: {pictures: {sizes}}} = r
        const sorted = sortBy(sizes, ['width'])
        const size = sorted.find(s => s.width > 200)
        setFieldValue('thumbnail', size.link, true)
      })
  }

  // Simply add 'Load Thumb' button at Form bottom
  render() {
    const {fields, dismiss, testObj} = this.props;
    let vSchema = validationSchema(fields)
    if(testObj){
      vSchema = vSchema.test(testObj)
    }

    return (
      <Formik
        enableReinitialize={true}
        initialValues={defaultSchema(fields)}
        validationSchema={vSchema}
        onSubmit={this.onSubmit}
      >
        {({ isValid, setFieldValue, values }) => (
        <Form className={style.form}>
          {fields.map((field, index) => {
            const type = typeMapping(field.type);
            return (type === 'select') ? (
              <FormikSelectField
                key={index}
                name={field.name}
                label={field.label}
                options={field.options}
                fullWidth
              />
            ) : (type === 'hidden') ? (
              <input
                type="hidden"
                key={index}
                name={field.name}
                value={field.initial}
              />
            ) : (type === 'boolean') ? (
              <FormikRadioGroupField
                key={index}
                name={field.name}
                label={field.label}
                options={[
                  { label: 'No', value: 'false' },
                  { label: 'Si', value: 'true' },
                ]}
                row="all"
                fullWidth
              />
            ) : (type === 'file') ? (
              <UploadDialog
                key={index}
                title={field.label}
                name={field.name}
                initial={field.initial}
                success={(name, url) => {
                  const upload = cloneDeep(this.state.upload)
                  upload[name] = url
                  this.setState({ upload: upload })
                }}
              />
            ) : (type === 'parent') ? (
              <ParentField
                key={index}
                title={field.label}
                all={field.options}
                current={field.initial}
                add={(assoc) => {
                  const parents = cloneDeep(this.state.parents)
                  parents.dean_id = assoc.id
                  this.setState({ parents: parents })
                }}
              />
            ) : (type === 'datetime') ? (
              <Field 
                key={index} 
                name={field.name} 
                label={field.label}
                component={FormikDateTime} 
                value={field.initial}
              />
            ) : (
              <FormikTextField
                key={index}
                name={field.name}
                label={field.label}
                margin="dense"
                multiline={type === 'textarea'}
                fullWidth
              />
            )}
          )}
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            disabled={!isValid && isEmpty(this.state.upload) && isEmpty(this.state.parents)}
          >
            Salva
          </Button>
          {dismiss && (
          <Button size="large" onClick={dismiss}>
            Indietro
          </Button>
          )}
          <Button 
            size="large" 
            color="primary"
            onClick={() => this.loadThumb(values, setFieldValue)}>
            Fill Thumb with vimeo data
          </Button>
        </Form>
        )}
      </Formik>
    )
  }
}

export default PillEntityForm