import React, { Component } from 'react';

import { clientInstance as client } from '../../api/upcademy.js'
import EntityForm from '../EntityForm/EntityForm'
import ModuleSummaryTable from '../SummaryTable/ModuleSummaryTable'
import form from '../../forms/module'

const initialState = {
  mode: 0,
  item: {},
}

class ModuleEditor extends Component {
  state = initialState

  commit = courseModule => {
    const {course, refresh} = this.props
    courseModule.number = Number(courseModule.number)
    if (courseModule.id === 0) {
      courseModule.course_id = course.id;
      return client.postModule(courseModule)
        .then(() => this.setState(initialState))  
        .then(() => refresh())
    }

    return client.putModule(courseModule)
      .then(() => this.setState(initialState))    
      .then(() => refresh())
  }

  remove = courseModule => {
    const {refresh} = this.props

    client.deleteModule(courseModule).then(() => refresh())
  }

  nav = (mode, item) => {
    this.setState({
      mode: mode,
      item: item,
    })
  }

  render() {
    const {items} = this.props
    const {mode, item} = this.state
    return (
      <>
      {mode === 0 && (
        <ModuleSummaryTable
          items={items}
          row={i => i.title}
          config={{
            add: () => this.nav(1, {}),
            edit: (cModule) => this.nav(1, cModule),
            delete: (cModule) => this.remove(cModule),
          }}
        />
      )}
      {mode === 1 && (
        <EntityForm
          fields={form(item)}
          success={this.commit}
          dismiss={() => this.nav(0, {})}
        />
      )}
      </>
    )
  }
}

export { ModuleEditor }
