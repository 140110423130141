import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Grid, Button, Typography, Avatar, Paper, withStyles } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { doGoogleSignIn } from '../../utility/firebase';
import googleSigniIn from '../../assets/images/btn_google_signin_dark_normal_web.png'

const styles = theme => ({
  main: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.primary.main,
  },
});

function Login({classes, authProgress}) {
  if (authProgress.user_id) {
    return <Redirect to="/academies"/>
  }

  return (
    <div className={classes.main}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in to the UP CMS
        </Typography>
        <Grid container className={classes.root} justify="center" alignItems="center">
          <Grid item >
            <Button onClick={doGoogleSignIn}>
              <img alt="" src={googleSigniIn}></img>
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

const mapState = (state) => {
  return {
    authProgress: state.authProgress,
  }
}

export default withStyles(styles)(connect(mapState)(Login))
