import { AUTH_PROGRESS } from '../actions/actionsTypes';

const authProgress = (state={firebaseReady: false, user_id: null}, action) => {
  const { type, firebaseReady, user_id=null } = action
  if (type === AUTH_PROGRESS){
    return {firebaseReady: firebaseReady, user_id: user_id}
  }else{
    return state
  }
}

export default authProgress