import React, { Component } from 'react';

import { clientInstance as client } from '../../api/upcademy.js'
import MaterialEntityForm from '../EntityForm/MaterialEntityForm'
import SummaryTable from '../SummaryTable/SummaryTable'
import form from '../../forms/material'

const initialValues = {
  mode: 0,
  item: {},
}

class MaterialEditor extends Component {
  state = initialValues

  commit = (material) => {
    const {lesson, refresh} = this.props
    if (material.id === 0) {
      material.lesson_id = lesson.id;
      return client.postMaterial(material)
        .then(() => this.setState(initialValues))  
        .then(() => refresh())
    }

    return client.putMaterial(material)
      .then(() => this.setState(initialValues))  
      .then(() => refresh())
  }

  remove = (material) => {
    const {refresh} = this.props
    client.deleteMaterial(material).then(() => refresh())
  }

  nav = (mode, item) => {
    this.setState({
      mode: mode,
      item: item,
    })
  }

  render() {
    const {items} = this.props
    const {mode} = this.state
    return (
      <>
      {mode === 0 && (
        <SummaryTable
          title="Materials"
          items={items}
          config={{
            add: () => this.nav(1, {}),
            edit: (material) => this.nav(1, material),
            delete: (material) => this.remove(material),
          }}
        />
      )}
      {mode === 1 && (
        <MaterialEntityForm
          fields={form(this.state.item)}
          success={this.commit}
          dismiss={() => this.nav(0, {})}
        />
      )}
      </>
    )
  }
}

export { MaterialEditor }
