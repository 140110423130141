import * as Yup from 'yup';

const form = event => [
  {
    name: 'id',
    type: 'hidden',
    initial: event.id || 0,
  },
  {
    name: 'faculty_id',
    type: 'hidden',
    initial: event.faculty_id || '',
  },
  {
    name: 'title',
    type: 'string',
    label: 'Titolo',
    validation: Yup.string().required(),
    initial: event.title || '',
  },
  {
    name: 'description',
    type: 'text',
    label: 'Descrizione',
    validation: Yup.string(),
    initial: event.description || '',
  },
  {
    name: 'video',
    type: 'string',
    label: 'Url youtube embdedded (Esempio: https://www.youtube.com/embed/GT6_1YZhkI0)',
    validation: Yup.string().required(),
    initial: event.video || '',
  },
  {
    name: 'start_at',
    type: 'datetime',
    label: "Data di inizio del live",
    validation: Yup.string().required(),
    initial: event.start_at || '',
  },
  {
    name: 'status',
    type: 'select',
    label: 'Stato di Pubblicazione',
    validation: Yup.string().required(),
    initial: event.status || 2,
    options: [
      {
        label: 'Draft',
        value: 2,
      },
      {
        label: 'Live',
        value: 3,
      },
      {
        label: 'Complete',
        value: 4,
      },
    ],
  }
];

export default form
