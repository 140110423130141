import React, { Component } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, omit } from 'lodash';
import { withRouter } from 'react-router-dom';

import * as actions from '../../actions';

import { clientInstance as client } from '../../api/upcademy.js';
import EntityForm from '../EntityForm/EntityForm';
import AssociationEditor from '../AssociationEditor/AssociationEditor';
import { tabs as SectionTabs } from './tabs';
import { TopicEditor } from './TopicEditor';
import { LessonEditor } from './LessonEditor';
import { ModuleEditor } from './ModuleEditor';
import form from '../../forms/course';

class CoursePanel extends Component {
  state = {
    index: 0,
    academyId: null,
    item: {},
    categories: [],
    teachers: [],
  }

  fetch() {
    const {create, match: {params: {id}}, getAcademy, setTitle} = this.props
    if (!create) {
      return client.getCourse(id)
        .then(resp => {
          const course = resp.data.course;
          setTitle(course.name)
          this.setState({
            academyId: course.academy_id,
            item: course,
          });

          getAcademy(course.academy_id);
          return client.getCategories(course.academy_id);
        })
        .then(resp => {
          const categories = resp.data.categories.map(cat => {
            return {
              value: cat.id,
              label: cat.name,
            }
          })
          this.setState({categories})

          return client.getTeachers(this.state.academyId);
        })
        .then(resp => {
          const teachers = resp.data.teachers.map(t => {
            return {
              value: t.id,
              label: t.name,
            }
          })
          this.setState({teachers})
          return this.state.item
        });
    }
  }

  parentId = () => {
    if (this.props.create) {
      return this.props.match.params.id;
    }

    return this.state.item.academy_id;
  }

  clearData = course => {
    const course_copy = omit(course, [
      'lessons',
      'modules',
      'syllabus',
    ])
    course_copy.teachers = course_copy.teachers && course_copy.teachers.map(i => ({id: i.id}))
    course_copy.categories = course_copy.categories && course_copy.categories.map(i => ({id: i.id}))
    return course_copy
  }

  commit = (course) => {
    if (course.id === 0) {
      course.academy_id = this.parentId()
      return client.postCourse(course).then(() => this.fetch())
    }

    return this.update((current) => {
      const updated = Object.assign(current, course)
      return this.clearData(updated)
    })
  }

  update = (apply) => {
    const course = cloneDeep(this.state.item);
    const result = apply(course)
    return client.putCourse(result)
      .then(() => this.fetch())
  }

  addTeacher = (assoc) => {
    return this.update((course) => {
      course.teachers = (course.teachers || []).concat([assoc]);
      return this.clearData(course)
    })
  }

  deleteTeacher = (teacher) => {
    return this.update((course) => {
      course.teachers = course.teachers.filter((t) => t.id !== teacher.id);
      return this.clearData(course)
    })
  }

  addCategory = (assoc) => {
    return this.update((course) => {
      course.categories = (course.categories || []).concat([assoc]);
      return this.clearData(course)
    })
  }

  deleteCategory = (category) => {
    return this.update((course) => {
      course.categories = course.categories.filter((t) => t.id !== category.id);
      return this.clearData(course)
    })
  }

  handleChange = (event, value) => {
    this.setState({ index: value });
  }

  render() {
    const {create, setTitle} = this.props;
    const {index, item: course, categories, teachers} = this.state;
    if (create) setTitle('')
    return (
      <>
        {!create && (
        <SectionTabs
          index={index}
          change={this.handleChange}
        />
        )}
        {index === 0 && (
        <EntityForm
          fields={form(course)}
          success={this.commit}
        />
        )}
        {index === 1 && !create && (
        <TopicEditor
          course={course}
          items={course.syllabus}
          refresh={this.fetch.bind(this)}
        />
        )}
        {index === 2 && !create && (
        <LessonEditor
          course={course}
          items={course.lessons}
          refresh={this.fetch.bind(this)}
        />
        )}
        {index === 3 && !create && (
        <ModuleEditor
          course={course}
          items={course.modules}
          refresh={this.fetch.bind(this)}
        />
        )}
        {index === 4 && !create && (
        <AssociationEditor
          title="Categorie"
          all={categories}
          current={course.categories}
          add={this.addCategory}
          remove={this.deleteCategory}
        />
        )}
        {index === 5 && !create && (
        <AssociationEditor
          title="Insegnanti"
          all={teachers}
          current={course.teachers}
          add={this.addTeacher}
          remove={this.deleteTeacher}
        />
        )}
      </>
    )
  }

  componentDidMount() {
    this.fetch()
  }
}

const mapDispatch = (dispatch) => {
  return {
    getAcademy: (id) => dispatch(actions.getOrFetchAcademy(id)),
    setTitle: title => dispatch(actions.setTitle(title)),
  }
}

export default withRouter(connect(null, mapDispatch)(CoursePanel))
