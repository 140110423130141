import * as Yup from 'yup';

const form = lesson => [
  {
    name: 'id',
    type: 'hidden',
    initial: lesson.id || 0,
  },
  {
    name: 'course_id',
    type: 'hidden',
    initial: lesson.course_id || '',
  },
  {
    name: 'number',
    type: 'string',
    label: 'Posizione',
    validation: Yup.number().positive().required(),
    initial: lesson.number || '',
  },
  {
    name: 'title',
    type: 'string',
    label: 'Titolo',
    validation: Yup.string().required(),
    initial: lesson.title || '',
  },
  {
    name: 'description',
    type: 'text',
    label: 'Descrizione',
    validation: Yup.string().required(),
    initial: lesson.description || '',
  },
  {
    name: 'video',
    type: 'string',
    label: 'Video ID',
    validation: Yup.string().required(),
    initial: lesson.video || '',
  },
  {
    name: 'duration',
    type: 'string',
    label: 'Durata video (in secondi)',
    validation: Yup.number().positive().required(),
    initial: lesson.duration || 0,
  },
  {
    name: 'module_id',
    type: 'select',
    label: 'Modulo',
    initial: lesson.module_id || 0,
    options: [],
  },
  {
    name: 'free',
    type: 'boolean',
    label: 'Gratis',
    validation: Yup.boolean(),
    initial: lesson.free ? 'true' : 'false',
  },
];

export default form
