import React, { Component } from 'react';
import { 
  AppBar, 
  Toolbar, 
  IconButton, 
  Button, 
  Paper, 
  TableBody, 
  Table, 
  TableHead, 
  TableRow, 
  TableCell } from '@material-ui/core';

import AddIcon from '@material-ui/icons/AddCircleOutline';
import DetailIcon from '@material-ui/icons/OpenInBrowser';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {Confirm} from './SummaryTable';

class ModuleSummaryTable extends Component {
  state = {
    itemToDelete: null,
  }
  
  closeConfirm = () => {
    this.setState({itemToDelete: null})
  }

  deleteItem = (item) => {
    const {config} = this.props
    this.closeConfirm()
    config.delete(item)
  }

  render() {
    const {items, row, config, title} = this.props;
    items.sort((a, b) => a.number - b.number)
    const {itemToDelete} = this.state
    const itemToDeleteName = itemToDelete ? itemToDelete.name || row(itemToDelete): ''

    return (
      <>
        <Confirm 
          isOpen={itemToDelete !== null} 
          callback={() => this.deleteItem(itemToDelete)} 
          close={this.closeConfirm}
          itemName={itemToDeleteName}
        />
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Posizione</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(items || []).map((item, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {item.id}
                  </TableCell>
                  <TableCell>
                    {item.number}
                  </TableCell>
                  <TableCell>
                    {item.name || row(item)}
                  </TableCell>
                  <TableCell>
                    {config.detail ? (
                    <Button
                      onClick={() => config.detail(item)}
                    >
                      <DetailIcon/>
                    </Button>
                    ) : null}
                    {config.edit ? (
                    <Button
                      onClick={() => config.edit(item)}
                    >
                      <EditIcon/>
                    </Button>
                    ) : null}
                    {config.delete ? (
                    <Button
                      onClick={() => this.setState({itemToDelete: item})}
                    >
                      <DeleteIcon/>
                    </Button>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        {config.add && (
        <AppBar position="relative" color="default">
          <Toolbar>
            {title && (
              title
            )}
            <IconButton color="inherit" onClick={config.add}>
              <AddIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        )}
      </>
    )
  }
}

ModuleSummaryTable.defaultProps = {
  config: {
    add: null,
    detail: null,
    edit: null,
    remove: null,
  },
};

export default ModuleSummaryTable
