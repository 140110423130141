import React from 'react';
import { AppBar, Toolbar, Typography } from '@material-ui/core';

function CustomAppBar ({className, title, position}) {
  return (
    <AppBar position={position} className={className}>
      <Toolbar>
        <Typography variant="h6" color="inherit" noWrap>
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export default CustomAppBar
