import * as apiEntities from '../api/entitiesConstant';
import { API_LOADING } from '../actions/actionsTypes';

const initialState = {
  [apiEntities.ACADEMIES]: false,
}

const apiLoading = (state=initialState, action) => {
  const  {type, payload } = action
  switch(type){
    case API_LOADING:
      return Object.assign({}, state, {[payload.scope]: payload.loading})
    default:
      return state
  }
}

export default apiLoading
