import * as Yup from 'yup';

import field from './status'

const form = (course) => [
  {
    name: 'id',
    type: 'hidden',
    initial: course.id || 0,
  },
  {
    name: 'academy_id',
    type: 'hidden',
    initial: course.academy_id || '',
  },
  {
    name: 'name',
    type: 'string',
    label: 'Nome',
    validation: Yup.string().required(),
    initial: course.name || '',
  },
  {
    name: 'description',
    type: 'text',
    label: 'Descrizione',
    validation: Yup.string().required(),
    initial: course.description || '',
  },
  {
    name: 'objective',
    type: 'text',
    label: 'Obiettivi',
    validation: Yup.string().required(),
    initial: course.objective || '',
  },
  {
    name: 'cover',
    type: 'file',
    label: 'Immagine di copertina',
    initial: course.cover,
  },
  field(course),
];

export default form
