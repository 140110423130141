import React, { Component } from 'react';
import { 
  AppBar, 
  Toolbar, 
  IconButton, 
  Button, 
  Paper, 
  TableBody, 
  Table, 
  TableHead, 
  TableRow, 
  TableCell,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/AddCircleOutline';
import DetailIcon from '@material-ui/icons/OpenInBrowser';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import style from './SummaryTable.module.css';

export const Mode = {
  light: 'light',
  dark: 'dark',
}

function CustomTableCell ({children, mode}) {
  const cellClasses = () => {
    switch (mode) {
      case Mode.dark:
        return {root: style.cellDark}
      default:
        return {}
    }
  }
  return (
    <TableCell classes={cellClasses()}>
      {children}
    </TableCell>
  )
}

export function Confirm({callback, itemName, close, isOpen}){
  return(
    <Dialog
      open={isOpen} 
      fullWidth
    >
      <DialogTitle>
        Conferma la cancellazione di <strong>{itemName}</strong>
      </DialogTitle>
      <DialogActions>
        <Button color="primary" onClick={callback}>
          Conferma
        </Button>
        <Button onClick={close}>
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  )

}

class SummaryTable extends Component {
  state = {
    itemToDelete: null,
  }

  closeConfirm = () => {
    this.setState({itemToDelete: null})
  }

  deleteItem = (item) => {
    const {config} = this.props
    this.closeConfirm()
    config.delete(item)
  }

  paperClasses = () => {
    const {mode=Mode.light} = this.props
    switch (mode) {
      case Mode.dark:
        return {root: style.paperDark}
      default:
        return {}
    }
  }

  render() {
    const {items, row, config, title, mode=Mode.light} = this.props;
    const {itemToDelete} = this.state
    const itemToDeleteName = itemToDelete ? itemToDelete.name || row(itemToDelete) : ''

    return (
      <>
        <Confirm 
          isOpen={itemToDelete !== null} 
          callback={() => this.deleteItem(itemToDelete)} 
          close={this.closeConfirm}
          itemName={itemToDeleteName}
        />
        <Paper classes={this.paperClasses()}>
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableCell mode={mode}>#</CustomTableCell>
                <CustomTableCell mode={mode}>Nome</CustomTableCell>
                <CustomTableCell mode={mode}>Azioni</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(items || []).map((item, index) => (
                <TableRow key={index}>
                  
                  <CustomTableCell 
                    mode={mode} 
                    component="th" 
                    scope="row" 
                    classes={{root: style.ttryme}}
                  >
                    {item.id}
                  </CustomTableCell>
                  
                  <CustomTableCell 
                    mode={mode} 
                    classes={{root: style.ttryme}}
                  >
                    {item.name || row(item)}
                  </CustomTableCell>

                  <CustomTableCell 
                    mode={mode} 
                    classes={{root: style.ttryme}}
                  >
                    {config.detail ? (
                    <Button
                      onClick={() => config.detail(item)}
                    >
                      <DetailIcon/>
                    </Button>
                    ) : null}
                    {config.edit ? (
                    <Button
                      onClick={() => config.edit(item)}
                    >
                      <EditIcon/>
                    </Button>
                    ) : null}
                    {config.delete ? (
                    <Button
                      onClick={() => this.setState({itemToDelete: item})}
                    >
                      <DeleteIcon/>
                    </Button>
                    ) : null}
                  </CustomTableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        {config.add && (
        <AppBar position="relative" color="default">
          <Toolbar>
            {title && (
              title
            )}
            <IconButton color="inherit" onClick={config.add}>
              <AddIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        )}
      </>
    )
  }
}

SummaryTable.defaultProps = {
  config: {
    add: null,
    detail: null,
    edit: null,
    remove: null,
  },
};

export default SummaryTable
