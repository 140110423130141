import * as Yup from 'yup';
import field from './status'

const form = (teacher) => [
  {
    name: 'id',
    type: 'hidden',
    initial: teacher.id || 0,
  },
  {
    name: 'academy_id',
    type: 'hidden',
    initial: teacher.academy_id || '',
  },
  {
    name: 'name',
    type: 'string',
    label: 'Nome',
    validation: Yup.string().required(),
    initial: teacher.name || '',
  },
  {
    name: 'email',
    type: 'string',
    label: 'Email',
    validation: Yup.string().email().required(),
    initial: teacher.email || '',
  },
  {
    name: 'position',
    type: 'string',
    label: 'Posizione',
    validation: Yup.string().required(),
    initial: teacher.position || '',
  },
  {
    name: 'description',
    type: 'text',
    label: 'Descrizione',
    validation: Yup.string().required(),
    initial: teacher.description || '',
  },
  {
    name: 'twitter',
    type: 'string',
    label: 'Profilo Twitter',
    validation: Yup.string(),
    initial: teacher.twitter || '',
  },
  {
    name: 'facebook',
    type: 'string',
    label: 'Profilo Facebook',
    validation: Yup.string(),
    initial: teacher.facebook || '',
  },
  {
    name: 'instagram',
    type: 'string',
    label: 'Profilo Instagram',
    validation: Yup.string(),
    initial: teacher.instagram || '',
  },
  {
    name: 'profile',
    type: 'file',
    label: 'Immagine del profilo',
    initial: teacher.profile,
  },
  {
    name: 'cover',
    type: 'file',
    label: 'Immagine di copertina',
    initial: teacher.cover,
  },
  field(teacher),
];

export default form
