import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../actions';
import { clientInstance as client } from '../api/upcademy.js'

import EntityList from './EntityList'

class CourseList extends Component {
  state = {
    items: [],
  }

  render() {
    return (
      <EntityList
        title="Corsi"
        items={this.state.items}
        add={(id) => `/academies/${id}/courses/new`}
        edit={(item) => `/courses/${item.id}`}
      />
    )
  }

  componentDidMount() {
    const {match: {params: {id}}, setTitle} = this.props
    setTitle('')
    this.props.getAcademy(id)
    client.getCourses(id)
      .then((resp) => {
        this.setState({ items: resp.data.courses })
      })
  }
}

const mapDispatch = (dispatch) => {
  return {
    getAcademy: (id) => dispatch(actions.getOrFetchAcademy(id)),
    setTitle: title => dispatch(actions.setTitle(title)),
  }
}

export default withRouter(connect(null, mapDispatch)(CourseList))
