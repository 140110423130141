import * as Yup from 'yup';

const field = (item) => {
  return {
    name: 'status',
    type: 'select',
    label: 'Stato di Pubblicazione',
    validation: Yup.string().required(),
    initial: item.status || 2,
    options: [
      {
        label: 'Draft',
        value: 2,
      },
      {
        label: 'Live',
        value: 3,
      },
    ],
  }
};

export default field
