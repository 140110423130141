import React, { Component } from 'react';

import SummaryTable, {Mode as summaryMode} from '../SummaryTable/SummaryTable'
import AssociationDialog from './AssociationDialog'

export const Mode = {
  default: 'default',
  dark: 'dark',
}

class AssociationEditor extends Component {
  state = {
    modal: false,
  }

  summaryTableMode = () => {
    const {mode=Mode.default} = this.props
    switch (mode) {
      case Mode.dark:
        return summaryMode.dark
      default:
        return summaryMode.light
    }
  }

  showModal = () => this.setState({ modal: true })

  hideModal = () => this.setState({ modal: false })

  render() {
    const {title, all, current} = this.props
    const {add, remove} = this.props
    return (
      <>
        <SummaryTable
          title={title}
          items={current}
          row={(item) => item.id}
          config={{
            add: this.showModal,
            delete: remove,
          }}
          mode={this.summaryTableMode()}
        />
        <AssociationDialog
          title={title}
          open={this.state.modal}
          all={all}
          current={current}
          success={(assoc) => {
            add(assoc)
            this.hideModal()
          }}
          dismiss={this.hideModal}
        />
      </>
    )
  }
}

export default AssociationEditor
