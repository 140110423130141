import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import Academy from '../Academy/Academy';

import './academiesList.css'

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
})

function AcademiesList ({classes, academies}) {
  return (
    <div className={classNames(classes.layout, classes.cardGrid)}>
      <Grid container spacing={40}>
        {academies.map((academy, index) => (
          <Grid item key={index} sm={6} md={4} lg={3}>
            <Academy academy={academy} />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default withStyles(styles)(AcademiesList)