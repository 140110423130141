import React, { Component } from 'react';

import { clientInstance as client } from '../../api/upcademy.js'
import EntityForm from '../EntityForm/EntityForm'
import SummaryTable from '../SummaryTable/SummaryTable'
import PillEditor from './PillEditor'
import form from '../../forms/pillSerie'

const initialState = {
  mode: 0,
  itemId: null,
}

class PillSerieEditor extends Component {
  state = initialState

  commit = (pillSerie) => {
    const {faculty, refresh} = this.props
    if (pillSerie.id === 0) {
      pillSerie.faculty_id = faculty.id;
      return client.postPillSerie(pillSerie)
        .then(() => this.setState(initialState))
        .then(() => refresh())
    }

    return client.putPillSerie(pillSerie)
      .then(() => this.setState(initialState))
      .then(() => refresh())
  }

  remove = (pillSerie) => {
    const {refresh} = this.props
    client.deletePillSerie(pillSerie).then(() => refresh())
  }

  nav = (mode, item) => {
    this.setState({
      mode: mode,
      itemId: item.id,
    })
  }

  render() {
    const {items, refresh} = this.props
    const {mode, itemId} = this.state
    let item = {}
    if(mode === 1 && itemId){
      item = items.find(i => i.id === itemId)
    }
    
    return (
      <>
      {mode === 0 && (
        <SummaryTable
          title="Pill Series"
          items={items}
          row={(pillSerie) => pillSerie.title}
          config={{
            add: () => this.nav(1, {}),
            edit: (pill) => this.nav(1, pill),
            delete: (pill) => this.remove(pill),
          }}
        />
      )}
      {mode === 1 && (
        <>
          <EntityForm
            fields={form(item)}
            success={this.commit}
            dismiss={() => this.nav(0, {})}
          />
          {item.id &&
          <PillEditor
            pillSerie={item}
            items={item.pills}
            refresh={refresh}
          />
          }
        </>
      )}
      </>
    )
  }
}

export default PillSerieEditor
