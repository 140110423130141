import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../actions';

class Unhauthorized extends Component {
  render(){
    return <Redirect to="/academies" />
  }

  componentDidMount(){
    this.props.resetData()
  }
}

const mapDispatch = (dispatch) => {
  return {
    resetData: () => {
      dispatch(actions.clearApiError())
    },
  }
}

export default connect(null, mapDispatch)(Unhauthorized)