import React from 'react';
import { Modal, Typography, withStyles, Button } from '@material-ui/core';
import { connect } from 'react-redux';

import * as actions from '../../actions';
import { styles, getModalStyle} from './utility';

function ApiErrorModal({classes, apiErrors, clearApiError}) {
  const handleClose = () => {
    clearApiError()
  }

  const error = apiErrors[Object.keys(apiErrors)[0]]

  const getMessage = () => {
    const {error: {status}} = error
    console.error(error.error.message)
    let errorStatusClass = null
    if (status >= 400 && status < 500) {
      errorStatusClass = 400 
    }else if(status >= 500) errorStatusClass = 500

    switch (errorStatusClass){
      case null:
        return "Problema di connessione"
      case 400: 
        return "Errore di validazione"
      case 500:
        return "Server error"
      default:
        return "Errore generico. Contatta il supporto."
    }
  }

  const displayError = typeof error !=='undefined'

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={displayError}
      onClose={handleClose}>
      {displayError ? 
        <div style={getModalStyle()} className={classes.paper}>
          <Typography variant="h6" id="modal-title">
            Api Error
          </Typography>
          <Typography variant="subtitle1" id="simple-modal-description">
            {getMessage()}
          </Typography>
          <Button 
              variant="contained" 
              className={classes.button}
              onClick={handleClose}>
            Dismiss
          </Button>
        </div> :
        <div></div>
      }
    </Modal>
  )
}

const mapState = (state) => {
  return {
    apiErrors: state.apiErrors
  }
}

const mapDispatch = (dispatch) => {
  return {
    clearApiError: () => dispatch(actions.clearApiError())
  }
}

export default withStyles(styles)(connect(mapState, mapDispatch)(ApiErrorModal))
