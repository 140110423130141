import React, {useState} from 'react';
import { withStyles, Drawer, List, ListItem, ListItemText, ListItemIcon, Divider} from '@material-ui/core';
import { withRouter } from 'react-router-dom';

const drawerWidth = 240;
const styles = {
 drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
};

function MainDrawer (props) {
  const {classes, items, history, location: {pathname}} = props
  const getInitialState = () => {
    if (pathname.includes('course')) return 0
    if (pathname.includes('facult')) return 1
    if (pathname.includes('teachers')) return 2
    if (pathname.includes('categories')) return 3
    return 0
  }

  const [itemIndex, setIndex] = useState(getInitialState())
  
  const handleClick = (action, index) => {
    history.push(action)
    setIndex(index)
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      anchor="left"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
    {items.map((itemGroup, groupIndex) => (
      <div key={groupIndex}>
        <List>
          {itemGroup.map((item, index) => (
            <ListItem 
              key={index} 
              button onClick={() => handleClick(item.action, index)} 
              selected={groupIndex === 0 && index === itemIndex}  
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </List>
        {items[groupIndex+1] ? <Divider/> : null}
      </div>
    ))}
    </Drawer>
  )
}

export default withRouter(withStyles(styles)(MainDrawer))
