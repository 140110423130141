import React, { Component } from 'react';
import { AppBar, Tabs, Tab } from '@material-ui/core';

class tabs extends Component {

  render() {
    const {index, change} = this.props;
    return (
      <AppBar position="static" color="default">
        <Tabs
          value={index}
          onChange={change}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Corso" />
          <Tab label="Syllabus" />
          <Tab label="Lezioni" />
          <Tab label="Moduli" />
          <Tab label="Categorie" />
          <Tab label="Insegnanti" />
        </Tabs>
      </AppBar>
    )
  }
}

export { tabs }
