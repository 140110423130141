import { API_FAILURE, API_FAILURE_CLEAR } from '../actions/actionsTypes';

const apiError = (state={}, action) => {
  const  {type, payload } = action
  if (type === API_FAILURE){
    console.error("Api error for entity: "+payload.entity+", message: ", payload.error)
    return Object.assign({}, state, {
      [payload.createdAt]: payload
    })
  }else if (type === API_FAILURE_CLEAR){
    return {}
  }else{
    return state
  }
}

export default apiError