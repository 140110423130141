import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { connect } from 'react-redux'

import * as actions from './actions';
import * as selectors from './reducers/selectors';
import { doSignOut } from './utility/firebase';
import { clientInstance } from './api/upcademy';

import Academies from './containers/Academies';
import MainContent from './components/MainContent/MainContent';
import CourseList from './containers/CourseList';
import CoursePanel from './components/CoursePanel/CoursePanel';
import CategoryEditor from './components/CategoryEditor/CategoryEditor';
import TeacherEditor from './components/TeacherEditor/TeacherEditor';
import FacultyEditor from './components/FacultyEditor/FacultyEditor';
import AcademyCommonBlock from './components/AcademyCommonBlock/AcademyCommonBlock';
import ApiErrorModal from './components/Modals/ApiErrorModal';
import ApiSuccessModal from './components/Modals/ApiSuccessModal';
import Login from './components/Login/Login';
import Unhauthorized from './components/Unhauthorized/Unhauthorized';

function AcademyRouting() {
  return (
    <div style={{display: 'flex'}}>
      <AcademyCommonBlock />
      <MainContent>
        <Switch>
          <Route
            path="/academies/:id"
            exact
            render={(args) => <Redirect to={`${args.match.url}/courses`} />} />
          <Route
            path="/academies/:id/courses"
            exact
            render={() => <CourseList />}/>
          <Route
            path="/courses/:id"
            exact
            render={() => <CoursePanel />}/>
          <Route
            path="/academies/:id/courses/new"
            exact
            render={() => <CoursePanel create={true} />}/>
          <Route
            path="/academies/:id/categories"
            exact
            render={() => <CategoryEditor />}/>
          <Route
            path="/academies/:id/teachers"
            exact
            render={() => <TeacherEditor />}/>
          <Route
            path="/academies/:id/faculties"
            exact
            render={() => <FacultyEditor />}/>
          <Redirect to="/academies" />
        </Switch>
      </MainContent>
    </div>
  )
}

function LoginRouting() {
  return (
    <Switch>
      <Route 
        path='/login'
        exact
        render={() => <Login />}
      />
      <Redirect to="/login" />
    </Switch>
  )
}

class App extends Component {
  isAuthorized = () => {
    const { apiErrors } = this.props
    let authorized = true
    for (const key in apiErrors){
      if (apiErrors[key].error.status === 401) authorized = false
    }
    return authorized
  }

  render(){
    const {authProgress: {firebaseReady, user_id}} = this.props
    if (!firebaseReady) return (<div></div>)
    if (!user_id) return <LoginRouting />
    if (!this.isAuthorized()) return <Unhauthorized />
    return (
      <>
        <CssBaseline/>
        <ApiErrorModal />
        <ApiSuccessModal />
        <Switch>
          <Route 
            path='/logout'
            exact
            render={() => {
              this.props.resetData()
              return <Redirect to="/" />
            }}
          />
          <Route
            path="/academies"
            exact
            render={() => <Academies />}
          />
          <Route
            path=""
            render={() => <AcademyRouting />}
          />
        </Switch>
      </>
    )
  }
}

const mapState = (state) => {
  return {
    user: selectors.getUser(state),
    authProgress: state.authProgress,
    apiErrors: state.apiErrors,
  }
}

const mapDispatch = (dispatch) => {
  return {
    resetData: () => {
      dispatch(actions.setAuthProgress({firebaseReady: false}))
      dispatch(actions.deleteUser())
      dispatch(actions.deleteCurrentAcademy())
      dispatch(actions.clearApiError())
      clientInstance.auth = {}
      doSignOut()
    },
  }
}

export default withRouter(connect(mapState, mapDispatch)(App));
