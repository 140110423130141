import React, { Component } from 'react';

import { clientInstance as client } from '../../api/upcademy.js'
import EntityForm from '../EntityForm/EntityForm'
import SummaryTable from '../SummaryTable/SummaryTable'
import form from '../../forms/concept'

const initialState = {
  mode: 0,
  item: {},
}

class ConceptEditor extends Component {
  state = initialState

  commit = (pill) => {
    const {faculty, refresh} = this.props
    if (pill.id === 0) {
      pill.faculty_id = faculty.id;
      return client.postConcept(pill)
        .then(() => this.setState(initialState))
        .then(() => refresh())
    }

    return client.putConcept(pill)
      .then(() => this.setState(initialState))
      .then(() => refresh())
  }

  remove = (pill) => {
    const {refresh} = this.props
    client.deleteConcept(pill).then(() => refresh())
  }

  nav = (mode, item) => {
    this.setState({
      mode: mode,
      item: item,
    })
  }

  render() {
    const {items} = this.props
    const {mode} = this.state
    return (
      <>
      {mode === 0 && (
        <SummaryTable
          title="Concepts"
          items={items}
          row={(pill) => pill.title}
          config={{
            add: () => this.nav(1, {}),
            edit: (pill) => this.nav(1, pill),
            delete: (pill) => this.remove(pill),
          }}
        />
      )}
      {mode === 1 && (
        <EntityForm
          fields={form(this.state.item)}
          success={this.commit}
          dismiss={() => this.nav(0, {})}
        />
      )}
      </>
    )
  }
}

export default ConceptEditor
