import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from "redux-saga";
import { BrowserRouter } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';
import rootReducers from './reducers';
import { sagas } from './sagas';

import App from './App';

import './index.css';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// dev tools middleware
const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

let composeFunction = () => compose(applyMiddleware(sagaMiddleware))
if (reduxDevTools) {
  composeFunction = () => compose(applyMiddleware(sagaMiddleware), reduxDevTools)
}

export const store = createStore(
  rootReducers, 
  composeFunction()
)

sagaMiddleware.run(sagas)

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
