import React, { Component } from 'react';
import { 
  AppBar, 
  Toolbar, 
  IconButton, 
  Button, 
  Paper, 
  TableBody, 
  Table, 
  TableHead, 
  TableRow, 
  TableCell } from '@material-ui/core';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { FormikSelectField } from 'formik-material-fields';

import AddIcon from '@material-ui/icons/AddCircleOutline';
import DetailIcon from '@material-ui/icons/OpenInBrowser';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {Confirm} from './SummaryTable';

import style from './SummaryTable.module.css';

class LiveEventSummaryTable extends Component {
  state = {
    itemToDelete: null,
    status: null,
  }
  
  closeConfirm = () => {
    this.setState({itemToDelete: null})
  }

  deleteItem = (item) => {
    const {config} = this.props
    this.closeConfirm()
    config.delete(item)
  }

  handleStatusChange = status => {
    const {onStatusChange} = this.props
    if (status === 1) status = null
    onStatusChange(status)
  }

  render() {
    const {items, row, config, title, initialStatus} = this.props;
    const {itemToDelete} = this.state
    const itemToDeleteName = itemToDelete ? itemToDelete.name || row(itemToDelete): ''

    const statusItem = item => {
      let color = '';
      let text = '';
      switch (item.status) {
        case 2:
          text = 'Draft';
          color = '#757575';
          break;
        case 3:
          color = 'green';
          text = 'Live'
          break;
        case 4:
          color = 'red';
          text = 'Completed'
          break;
        default:
          color = '#757575';
          text = 'Not defined';
      }
      
      return (
        <strong style={{color}}>{text}</strong>
      )
    }

    const statusSelectOptions = [
      {label: 'All', value: 1},
      {label: 'Draft', value: 2},
      {label: 'Live', value: 3},
      {label: 'Complete', value: 4},
    ]

    return (
      <>
        <Confirm 
          isOpen={itemToDelete !== null} 
          callback={() => this.deleteItem(itemToDelete)} 
          close={this.closeConfirm}
          itemName={itemToDeleteName}
        />
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Stato</TableCell>
                <TableCell>Data inizio evento</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(items || []).map((item, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {item.id}
                  </TableCell>
                  <TableCell>
                    {statusItem(item)}
                  </TableCell>
                  <TableCell>
                    {moment(item.start_at).format('D MMM YYYY HH:mm') }
                  </TableCell>
                  <TableCell>
                    {item.name || row(item)}
                  </TableCell>
                  <TableCell>
                    {config.detail ? (
                    <Button
                      onClick={() => config.detail(item)}
                    >
                      <DetailIcon/>
                    </Button>
                    ) : null}
                    {config.edit ? (
                    <Button
                      onClick={() => config.edit(item)}
                    >
                      <EditIcon/>
                    </Button>
                    ) : null}
                    {config.delete ? (
                    <Button
                      onClick={() => this.setState({itemToDelete: item})}
                    >
                      <DeleteIcon/>
                    </Button>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        {config.add && (
        <AppBar position="relative" color="default">
          <Toolbar>
            {title && (
              title
            )}
            <IconButton color="inherit" onClick={config.add}>
              <AddIcon />
            </IconButton>
            <div className={style.liveEventSelectWrap}>
              <Formik
                initialValues={{status: initialStatus}}
              >
                <Form>
                  <FormikSelectField 
                    name='status'
                    label='Filtra stato'
                    options={statusSelectOptions}
                    onChange={event => {this.handleStatusChange(event.target.value)}}
                    className={style.liveEventSelect}
                  />
                </Form>    
              </Formik>
            </div>
          </Toolbar>
        </AppBar>
        )}
      </>
    )
  }
}

LiveEventSummaryTable.defaultProps = {
  config: {
    add: null,
    detail: null,
    edit: null,
    remove: null,
  },
};

export default LiveEventSummaryTable
