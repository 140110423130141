import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';

import { clientInstance as client } from './upcademy.js'

const clientReq = obj => {
  return client.postObject(obj)
    .then(resp => resp.data)
    .then(data => {
      return {
        method: data.method,
        url: data.url,
        fields: data.fields
      }
    })
}

const uploadParams = (file) => {
  const object = {
    name: file.name,
    type: file.type,
  }
  return clientReq(object)
}

const uploadGenericParams = (file) => {
  const object = {
    name: file.name,
    type: file.type,
    folder: 'generic-doc',
    real_name: true,
  }
  return clientReq(object)
}

const uploadMp3Params = (file) => {
  const object = {
    name: file.name,
    type: file.type,
    folder: 'audio',
    real_name: true,
  }
  return clientReq(object)
}

const uploadPdfParams = (file) => {
  const object = {
    name: file.name,
    type: file.type,
    folder: 'doc',
    real_name: true,
  }
  return clientReq(object)
}

class Uploader {

  constructor(name, success) {
    this.uppy = Uppy({
      meta: { fieldName: name },
      restrictions: {
        maxNumberOfFiles: 1,
      },
      autoProceed: true,
    })

    this.uppy.use(AwsS3, {
      limit: 1,
      getUploadParameters: uploadParams,
    })

    this.uppy.on('complete', (result) => {
      if (!result.successful || result.successful.length === 0) {
        return;
      }
      
      success(result.successful[0])
    })

  }
}

export class PdfUploader {
  constructor(name, success) {
    
    this.uppy = Uppy({
      meta: { fieldName: name },
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: [
          'application/pdf',
          'application/epub',
          'application/epub+zip',
        ],
      },
      autoProceed: true,
    })

    this.uppy.use(AwsS3, {
      limit: 1,
      getUploadParameters: uploadPdfParams,
    })


    this.uppy.on('complete', (result) => {
      if (!result.successful || result.successful.length === 0) {
        return;
      }
      
      success(result.successful[0])
    })
  }
}

export class GenericDocUploader {
  constructor(name, success) {
    
    this.uppy = Uppy({
      meta: { fieldName: name },
      restrictions: {
        maxNumberOfFiles: 1,
      },
      autoProceed: true,
    })

    this.uppy.use(AwsS3, {
      limit: 1,
      getUploadParameters: uploadGenericParams,
    })


    this.uppy.on('complete', (result) => {
      if (!result.successful || result.successful.length === 0) {
        return;
      }
      
      success(result.successful[0])
    })
  }
}

export class Mp3Uploader {
  constructor(name, success) {
    
    this.uppy = Uppy({
      meta: { fieldName: name },
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['.mp3', '.ogg', '.wav', '.m4a'],
      },
      autoProceed: true,
    })

    this.uppy.use(AwsS3, {
      limit: 1,
      getUploadParameters: uploadMp3Params,
    })


    this.uppy.on('complete', (result) => {
      if (!result.successful || result.successful.length === 0) {
        return;
      }
      
      success(result.successful[0])
    })
  }
}

export default Uploader
