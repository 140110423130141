import { combineReducers } from 'redux';
import apiLoading from './apiLoading';
import entities from './entities';
import apiErrors from './apiErrors';
import successModal from './successModal';
import authProgress from './authProgress';
import pageTitle from './pageTitle';

export default combineReducers({
  apiLoading,
  entities,
  apiErrors,
  successModal,
  authProgress,
  pageTitle,
})
