import React from 'react';
import Datetime from 'react-datetime';
import moment from 'moment';

import style from './FormikDateTime.module.css';
import 'react-datetime/css/react-datetime.css';

// take from here
// https://github.com/YouCanBookMe/react-datetime/issues/598#issuecomment-440518051

const FormikDateTime = ({ field, form, value, label }) => {
  const onFieldChange = value => {
    form.setFieldValue(field.name, value);
  }

  const onFieldBlur = () => {
    form.setFieldTouched(field.name, true);
  }

  return (
    <div className={style.root}>
      <label className={style.label}>{label}</label>
      <div className={style.datetimeWrap}>
        <Datetime
          dateFormat={'DD/MM/YYYY'}
          timeFormat={'hh:mm A'}
          defaultValue={moment(value)}
          id={field.name}
          name={field.name}
          onChange={onFieldChange}
          onBlur={onFieldBlur}
          className={style.custom}
        />
      </div>
    </div>
  );
}

export default FormikDateTime;