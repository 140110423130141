import { SET_SUCCESS_MODAL } from '../actions/actionsTypes';
import { setSuccessModal } from '../actions';
import { store } from '../index';

const successModal = (state=false, action) => {
  const { type, value } = action
  if (type === SET_SUCCESS_MODAL){
    if (value) {
      setTimeout(() => {
        store.dispatch(setSuccessModal(false))
      }, 600)
    }

    return value
  }

  return state
}

export default successModal
