import React, { Component } from 'react';
import { CssBaseline } from '@material-ui/core';

import { clientInstance as client } from '../api/upcademy';

import AcademiesList from '../components/AcademiesList/AcademiesList';
import CustomAppBar from '../components/CustomAppBar/CustomAppBar'
import TopDescription from '../components/TopDescription/TopDescription';

class Academies extends Component {
  state = {
    academies: []
  }

  render(){
    return (
      <>
        <CssBaseline />
        <main>
          <CustomAppBar title="Upcademy - CMS" position="static"/>
          <TopDescription title="Academies" description="Select your academy"/>
          <AcademiesList academies={this.state.academies}/>
        </main>
      </>
    )
  }

  componentDidMount(){
    client.getAcademies()
      .then(response => {
        this.setState({academies: response.data.academies})
      })
  }
}


export default Academies
