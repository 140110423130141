import React from 'react';
import { Card, CardContent, withStyles, CardMedia, Typography, CardActions, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import AtenaImg from '../../assets/images/0-12631.jpg'

import './academy.css';

const styles = {
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
}

function Academy ({classes, academy, history}) {
  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.cardMedia}
        image={AtenaImg}
        title="Image title"
      />
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="h5" component="h2">
          {academy.name}
        </Typography>
        <Typography>
          {/* Desscription here */}
        </Typography>
      </CardContent>
      <CardActions>
        <Button 
            size="small" 
            color="primary" 
            onClick={() => {history.push(`/academies/${academy.id}`)}}>
          Manage
        </Button>
      </CardActions>
    </Card>
  )
}

export default withRouter(withStyles(styles)(Academy))
