export const getApiLoading = ({apiLoading}) => {
  return apiLoading
}

export const getCurrentAcademy = ({entities}) => {
  return entities.currentAcademy
}

export const getApiErrors = ({apiErrors}) => {
  return apiErrors
}

export const getUser = ({entities}) => {
  return entities.user
}

export const getTitle = ({pageTitle}) => {
  return pageTitle
}
