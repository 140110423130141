import { call, put, all, takeEvery, select } from 'redux-saga/effects';

import * as entities from '../api/entitiesConstant';
import * as actionsType from '../actions/actionsTypes';
import * as actions from '../actions';
import * as  selectors from '../reducers/selectors';
import { apiGet } from '../api/utility';

function* apiRequestWatch() {
  yield takeEvery(actionsType.API_REQUEST, fetchData);
}

function* apiRequestIfNotInStateWatch() {
  yield takeEvery(actionsType.API_REQUEST_IF_NOT_IN_STATE, checkData);
}

export function* sagas() {
  yield all([
    apiRequestWatch(),
    apiRequestIfNotInStateWatch(),
  ])
}

function* handleGetResponse(response, action) {
  yield put(actions.setApiSuccess({ entity: action.entity, data: response.data }));
}

function* fetchData(action) {
  const {entity, params} = action
  try {
    yield put(actions.setApiLoading({entity: entity, loading: true}))
    const response = yield call(apiGet, {entity: entity, params: params})
    yield put(actions.setApiLoading({entity: entity, loading: false}))

    switch (entity) {
      case entities.ACADEMIES:
        yield handleGetResponse(response, action);
        break
      case entities.USER:
        yield handleGetResponse(response, action);
        break
      default:
        break
    }
  } catch (err) {
    yield put(actions.setApiLoading({entity: entity, loading: false}))
  }
}

function* checkData(action) {
  if (action.entity === entities.ACADEMIES){
    const academy = yield select(selectors.getCurrentAcademy)
    if (!academy.id){
      yield put(actions.getAcademy(action.params.id))
    }
  }
}
