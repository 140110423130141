import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { isEmpty, cloneDeep } from 'lodash';
import * as Yup from 'yup';
import { FormikTextField, FormikSelectField, FormikRadioGroupField } from 'formik-material-fields';
import FormikDateTime from '../FormikDateTime/FormikDateTime';

import UploadDialog from '../UploadDialog/UploadDialog';
import ParentField from '../AssociationEditor/ParentField';

import style from './EntityForm.module.css';

export const defaultSchema = model => {
  const schema = {};
  model.forEach(field => {
    schema[field.name] = field.initial;
  });
  return schema;
};

export const validationSchema = (model) => {
  const schema = {};
  model.forEach((field) => {
    if (field.validation) {
      schema[field.name] = field.validation;
    }
  });

  return Yup.object().shape(schema);
}

export const typeMapping = (type) => {
  switch (type) {
    case 'string':
      return 'input';
    case 'hidden':
      return 'hidden';
    case 'text':
      return 'textarea';
    case 'select':
      return 'select';
    case 'file':
      return 'file';
    case 'parent':
      return 'parent';
    case 'boolean':
      return 'boolean';
    case 'datetime':
      return 'datetime';
    default:
  }

  return 'input';
}

class EntityForm extends Component {
  state = {
    upload: {},
    parents: {},
  }

  onSubmit = (res) => {
    Object.keys(this.state.upload).forEach((key) => {
      res[key] = this.state.upload[key]
    })

    Object.keys(this.state.parents).forEach((key) => {
      res[key] = this.state.parents[key]
    })

    this.props.success(res)
  }

  render() {
    const {fields, dismiss, testObj} = this.props;
    let vSchema = validationSchema(fields)
    if(testObj){
      vSchema = vSchema.test(testObj)
    }
    return (
      <Formik
        enableReinitialize={true}
        initialValues={defaultSchema(fields)}
        validationSchema={vSchema}
        onSubmit={this.onSubmit}
      >
        {({ isValid }) => (
        <Form className={style.form}>
          {fields.map((field, index) => {
            const type = typeMapping(field.type);
            return (type === 'select') ? (
              <FormikSelectField
                key={index}
                name={field.name}
                label={field.label}
                options={field.options}
                fullWidth
              />
            ) : (type === 'hidden') ? (
              <input
                type="hidden"
                key={index}
                name={field.name}
                value={field.initial}
              />
            ) : (type === 'boolean') ? (
              <FormikRadioGroupField
                key={index}
                name={field.name}
                label={field.label}
                options={[
                  { label: 'No', value: 'false' },
                  { label: 'Si', value: 'true' },
                ]}
                row="all"
                fullWidth
              />
            ) : (type === 'file') ? (
              <UploadDialog
                key={index}
                title={field.label}
                name={field.name}
                initial={field.initial}
                success={(name, url) => {
                  const upload = cloneDeep(this.state.upload)
                  upload[name] = url
                  this.setState({ upload: upload })
                }}
              />
            ) : (type === 'parent') ? (
              <ParentField
                key={index}
                title={field.label}
                all={field.options}
                current={field.initial}
                add={(assoc) => {
                  const parents = cloneDeep(this.state.parents)
                  parents.dean_id = assoc.id
                  this.setState({ parents: parents })
                }}
              />
            ) : (type === 'datetime') ? (
              <Field 
                key={index} 
                name={field.name} 
                label={field.label}
                component={FormikDateTime} 
                value={field.initial}
              />
            ) : (
              <FormikTextField
                key={index}
                name={field.name}
                label={field.label}
                margin="dense"
                multiline={type === 'textarea'}
                fullWidth
              />
            )}
          )}
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            disabled={!isValid && isEmpty(this.state.upload) && isEmpty(this.state.parents)}
          >
            Salva
          </Button>
          {dismiss && (
          <Button size="large" onClick={dismiss}>
            Indietro
          </Button>
          )}
        </Form>
        )}
      </Formik>
    )
  }
}

export default EntityForm
