import React, { Component } from 'react';
import {cloneDeep} from 'lodash';

import { clientInstance as client } from '../../api/upcademy.js'
import EntityForm from '../EntityForm/EntityForm'
import LiveEventSummaryTable from '../SummaryTable/LiveEventSummaryTable'
import AssociationEditor, {Mode as associationEditorMode} from '../AssociationEditor/AssociationEditor'
import form from '../../forms/liveEvent'

const initialMode = {
  mode: 0,
  item: {},
}

const initialState = {
  ...initialMode,
  teachers: [],
}

class LiveEventEditor extends Component {
  state = initialState

  commit = event => {
    const {faculty, refresh} = this.props
    const {teachers} = this.state.item
    if (event.id === 0) {
      event.faculty_id = faculty.id;
      return client.postLiveEvent(event)
        .then(() => this.setState(initialMode))
        .then(() => refresh())
    }
    event.teachers = teachers
    return client.putLiveEvent(event)
      .then(() => this.setState(initialMode))
      .then(() => refresh())
  }

  remove = event => {
    const {refresh} = this.props
    client.deleteLiveEvent(event).then(() => refresh())
  }

  nav = (mode, item) => {
    this.setState({
      mode: mode,
      item: item,
    })
  }

  updateAll = live_event => {
    const {refresh} = this.props
    return client.putLiveEvent(live_event)
      .then(() => refresh())
      .then(() => client.getLiveEvent(live_event))
      .then(({data:{live_event}}) => this.setState({item: live_event}))
  }

  addTeacher = assoc => {
    const live_event = cloneDeep(this.state.item);
    live_event.teachers = (live_event.teachers || []).concat([assoc]);
    return this.updateAll(live_event)
  }

  deleteTeacher = teacher => {
    const live_event = cloneDeep(this.state.item);
    live_event.teachers = live_event.teachers.filter((t) => t.id !== teacher.id);
    return this.updateAll(live_event)
  }

  creating = () => {
    return typeof this.state.item.id === 'undefined'
  }

  render() {
    const {items, onStatusChange, initialValue} = this.props
    const {mode, teachers, item} = this.state
    return (
      <>
      {mode === 0 && (
        <LiveEventSummaryTable
          title="Live Events"
          items={items}
          row={(event) => event.title}
          onStatusChange={onStatusChange}
          initialStatus={initialValue}
          config={{
            add: () => this.nav(1, {}),
            edit: (event) => this.nav(1, event),
            delete: (event) => this.remove(event),
          }}
        />
      )}
      {mode === 1 && (
        <>
          <EntityForm
            fields={form(item)}
            success={this.commit}
            dismiss={() => this.nav(0, {})}
          />
          {!this.creating() && (
          <AssociationEditor
            title="Insegnanti"
            all={teachers}
            current={item.teachers || []}
            add={this.addTeacher}
            remove={this.deleteTeacher}
            mode={associationEditorMode.dark}
          /> 
          )}
        </> 
      )}
      </>
    )
  }

  componentDidMount(){
    const {academy_id} = this.props.faculty
    client.getTeachers(academy_id)
      .then(resp => {
        const teachers = resp.data.teachers.map((t) => {
          return {
            value: t.id,
            label: t.name,
          }
        })
        this.setState({teachers})
      })
  }
}

export default LiveEventEditor
