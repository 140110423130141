import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { cloneDeep, isEmpty, omit } from 'lodash';
import { connect } from 'react-redux';

import * as actions from '../../actions';

import EntityForm from '../EntityForm/EntityForm'
import SummaryTable from '../SummaryTable/SummaryTable'
import AssociationEditor from '../AssociationEditor/AssociationEditor'
import PillSerieEditor from './PillSerieEditor'
import ConceptEditor from './ConceptEditor'
import NewsEditor from './NewsEditor'
import LiveEventEditor from './LiveEventEditor'
import { clientInstance as client } from '../../api/upcademy.js'
import form from '../../forms/faculty'

class FacultyEditor extends Component {

  // null -> All
  // 2    -> Draft
  // 3    -> Live
  // 4    -> Complete
  liveEventInitial = 3

  state = {
    mode: 0,
    item: {},
    items: [],
    categories: [],
    teachers: [],
    courses: [],
    articles: [],
    live_events: [],
    live_events_status: this.liveEventInitial,
  }

  fetch = () => {
    const {match: {params: {id}}} = this.props
    const {item} = this.state
    client.getFaculties(id)
      .then(resp => {
        const faculties = resp.data.faculties
        faculties.forEach((faculty) => {
          if (faculty.id === item.id) {
            this.setState({ item: faculty })
          }
        })

        this.setState({ items: faculties })
      })
      .then(() => client.getCategories(id))
      .then(resp => {
        const categories = resp.data.categories.map((cat) => {
          return {
            value: cat.id,
            label: cat.name,
          }
        })
        this.setState({ categories: categories })
      })
      .then(() => client.getTeachers(id))
      .then(resp => {
        const teachers = resp.data.teachers.map((t) => {
          return {
            value: t.id,
            label: t.name,
          }
        })
        this.setState({ teachers: teachers })
      })
      .then(() => client.getCourses(id))
      .then(resp => {
        const courses = resp.data.courses.map((c) => {
          return {
            value: c.id,
            label: c.name,
          }
        })
        this.setState({ courses: courses })
      })
      .then(() => {
        if (!isEmpty(item)){
          this.fetchFacultyRelated()
        }
      })
  }

  fetchFacultyRelated = () => {
    const {item} = this.state
    client.getArticles({id: item.id})
    .then(resp => {
      const articles = resp.data.articles.map((a) => {
        return {
          value: a.id,
          label: a.title,
        }
      })
      return this.setState({ articles })
    })
    .then(() => this.getLiveEvents())
    .then(live_events => this.setState({live_events}))
  }

  getLiveEvents = () => {
    const {item, live_events_status} = this.state
    return client.getLiveEvents(item.id, live_events_status)
      .then(resp => resp.data.live_events)
  }

  clearData = faculty => {
    const faculty_copy = omit(faculty, [
      'articles',
      'concepts',
      'dean',
      'live_events',
      'pill_series',
    ])
    if (typeof(faculty.dean_id) !== "number"){
      faculty_copy.dean_id = faculty.dean_id.id
    }
    faculty_copy.courses = faculty_copy.courses && faculty_copy.courses.map(i => ({id: i.id}))
    faculty_copy.categories = faculty_copy.categories && faculty_copy.categories.map(i => ({id: i.id}))
    faculty_copy.teachers = faculty_copy.teachers && faculty_copy.teachers.map(i => ({id: i.id}))
    return faculty_copy
  }

  commit = (faculty) => {
    if (faculty.id === 0) {
      faculty.academy_id = this.props.match.params.id;
      return client.postFaculty(faculty).then(() => this.fetch())
    }

    return this.update((current) => {
      const updated = Object.assign(current, faculty)
      return this.clearData(updated)
    })
  }

  nav = (mode, item) => {
    const {setTitle} = this.props
    this.setState({
      mode: mode,
      item: item,
    }, function () {
      if(mode === 0){
        setTitle('')
        this.setState({articles: []})
      }else if(mode === 1){
        const {item} = this.state
        if (item.name) setTitle(item.name)
        this.fetchFacultyRelated()
      }
    })
  }

  update = (apply) => {
    const faculty = cloneDeep(this.state.item);
    const result = apply(faculty)
    return client.putFaculty(result)
      .then(() => this.fetch())
  }

  addTeacher = (assoc) => {
    return this.update((faculty) => {
      faculty.teachers = (faculty.teachers || []).concat([assoc]);
      return this.clearData(faculty)
    })
  }

  deleteTeacher = (teacher) => {
    return this.update((faculty) => {
      faculty.teachers = faculty.teachers.filter((t) => t.id !== teacher.id);
      return this.clearData(faculty)
    })
  }

  addCategory = (assoc) => {
    return this.update((faculty) => {
      faculty.categories = (faculty.categories || []).concat([assoc]);
      return this.clearData(faculty)
    })
  }

  deleteCategory = (category) => {
    return this.update((faculty) => {
      faculty.categories = faculty.categories.filter((t) => t.id !== category.id);
      return this.clearData(faculty)
    })
  }

  addCourse = (assoc) => {
    return this.update((faculty) => {
      faculty.courses = (faculty.courses || []).concat([assoc]);
      return this.clearData(faculty)
    })
  }

  deleteCourse = (course) => {
    return this.update((faculty) => {
      faculty.courses = faculty.courses.filter((t) => t.id !== course.id);
      return this.clearData(faculty)
    })
  }

  addArticle = () => {
    // TODO
    console.log("addArticle Implement me!!")
  }

  deleteArticle = () => {
    // TODO
    console.log("deleteArticle Implement me!!")
  }

  handleStatusChange = status => {
    this.setState({live_events_status: status}, () => {
      this.getLiveEvents()
        .then(live_events => this.setState({live_events}))
    })
  }

  render() {
    const {mode, items, item, live_events_status} = this.state
    return (
      <>
      {mode === 0 && (
        <SummaryTable
          title="Facoltà"
          items={items}
          config={{
            add: () => this.nav(1, {}),
            edit: (teacher) => this.nav(1, teacher),
          }}
        />
      )}
      {mode === 1 && (
        <>
          <EntityForm
            fields={form(item, this.state.teachers)}
            success={this.commit}
            dismiss={() => this.nav(0, {})}
          />
          <ConceptEditor
            faculty={item}
            items={item.concepts}
            refresh={this.fetch}
          />
          <PillSerieEditor
            faculty={item}
            items={item.pill_series}
            refresh={this.fetch}
          />
          <AssociationEditor
            title="Categorie"
            all={this.state.categories}
            current={item.categories}
            add={this.addCategory}
            remove={this.deleteCategory}
          />
          <AssociationEditor
            title="Insegnanti"
            all={this.state.teachers}
            current={item.teachers}
            add={this.addTeacher}
            remove={this.deleteTeacher}
          />
          <AssociationEditor
            title="Corsi"
            all={this.state.courses}
            current={item.courses}
            add={this.addCourse}
            remove={this.deleteCourse}
          />
          <NewsEditor
            faculty={item}
            items={item.articles}
            refresh={this.fetch}
          />
          <LiveEventEditor
            faculty={item}
            items={this.state.live_events}
            refresh={this.fetch}
            onStatusChange={this.handleStatusChange}
            initialValue={live_events_status ? live_events_status : 1}
          />
        </>
      )}
      </>
    )
  }

  componentDidMount() {
    const {match: {params: {id}}, setTitle} = this.props
    this.props.getAcademy(id)
    this.fetch()
    setTitle('')
  }
}

const mapDispatch = (dispatch) => {
  return {
    getAcademy: (id) => dispatch(actions.getOrFetchAcademy(id)),
    setTitle: title => dispatch(actions.setTitle(title)),
  }
}

export default withRouter(connect(null, mapDispatch)(FacultyEditor))
