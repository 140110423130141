import * as Yup from 'yup';

const form = cModule => [
  {
    name: 'id',
    type: 'hidden',
    initial: cModule.id || 0,
  },
  {
    name: 'course_id',
    type: 'hidden',
    initial: cModule.course_id || '',
  },
  {
    name: 'number',
    type: 'string',
    label: 'Posizione',
    validation: Yup.number().positive().required(),
    initial: cModule.number || '',
  },
  {
    name: 'title',
    type: 'string',
    label: 'Titolo',
    validation: Yup.string().required(),
    initial: cModule.title || '',
  },
  {
    name: 'description',
    type: 'text',
    label: 'Descrizione',
    validation: Yup.string(),
    initial: cModule.description || '',
  },
];

export default form
