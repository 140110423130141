import * as Yup from 'yup';

const form = (concept) => [
  {
    name: 'id',
    type: 'hidden',
    initial: concept.id || 0,
  },
  {
    name: 'faculty_id',
    type: 'hidden',
    initial: concept.faculty_id || '',
  },
  {
    name: 'title',
    type: 'string',
    label: 'Titolo',
    validation: Yup.string().required(),
    initial: concept.title || '',
  }
];

export default form
