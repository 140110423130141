import { clientInstance as client} from '../api/upcademy';
import * as entities from './entitiesConstant';

const apiGet = (config) => {
  const { entity, params } = config;
  switch (entity) {
    case entities.ACADEMIES:
      return client.getAcademy(params.id)
    case entities.USER:
      return client.getUser(params.id)
    default:
      console.error("Try to request an entity not handled: ", entity)
      return
  }
}

const getStructuredError = err => {
  const error = {}
  const errorMessage = err.message || "Unknow error" 
  const dataError = (err.response && err.response.data && err.response.data.message) || ""
  error.message = dataError !== '' ? `${errorMessage}, ${dataError}` : errorMessage
  error.status = err.response && err.response.status ? err.response.status : null
  return error
}

export { apiGet, getStructuredError }
