import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
  },
});

class MainContent extends Component {
  render() {
    const {children, classes} = this.props;
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    )
  }
}

export default withStyles(styles)(MainContent)
