import React, { Component } from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { FormikSelectField } from 'formik-material-fields';

const formKey = 'FORM_DIALOG'

class AssociationDialog extends Component {

  render() {
    const {title, label, open} = this.props;
    const {all, current} = this.props;
    const {success, dismiss} = this.props;
    const options = all.filter((x) => {
      return (current || []).filter((y) => y.id === x.value).length === 0;
    });
    return (
      <Dialog
        aria-labelledby="dialog-title"
        open={open}
        fullWidth
      >
        <DialogTitle id="dialog-title">
          {title}
        </DialogTitle>
        <Formik
          onSubmit={success}
          initialValues={{id: 0}}
        >
          <Form>
            <input
              type="hidden"
              name="formKey"
              value={formKey}
            />
            <DialogContent>
              {options.length ? (
              <FormikSelectField
                name="id"
                label={label}
                options={options}
                fullWidth
              />
              ) : (
              <DialogContentText>
                Entità già associata a tutto
              </DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              {options.length ? (
              <Button type="submit" color="primary">
                Conferma
              </Button>
              ) : null}
              <Button onClick={dismiss}>
                Annulla
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    )
  }
}

export { formKey }
export default AssociationDialog
