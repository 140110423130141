import * as Yup from 'yup';

const form = (pill) => [
  {
    name: 'id',
    type: 'hidden',
    initial: pill.id || 0,
  },
  {
    name: 'pill_serie_id',
    type: 'hidden',
    initial: pill.pill_serie_id || '',
  },
  {
    name: 'title',
    type: 'string',
    label: 'Titolo',
    validation: Yup.string().required(),
    initial: pill.title || '',
  },
  {
    name: 'description',
    type: 'text',
    label: 'Descrizione',
    validation: Yup.string(),
    initial: pill.description || '',
  },
  {
    name: 'video',
    type: 'string',
    label: 'Video ID',
    validation: Yup.string().required(),
    initial: pill.video || '',
  },
  {
    name: 'duration',
    type: 'string',
    label: 'Durata video (in secondi)',
    validation: Yup.number().positive().required(),
    initial: pill.duration || 0,
  },
  {
    name: 'thumbnail',
    type: 'string',
    label: 'Thumbnail Url',
    validation: Yup.string().required(),
    initial: pill.thumbnail || '',
  },
];

export default form
