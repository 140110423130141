import React, { Component } from 'react';
import { cloneDeep, omit } from 'lodash';

import { clientInstance as client } from '../../api/upcademy.js'
import EntityForm from '../EntityForm/EntityForm'
import LessonSummaryTable from '../SummaryTable/LessonSummaryTable'
import form from '../../forms/lesson'
import AssociationEditor from '../AssociationEditor/AssociationEditor'
import { MaterialEditor } from './MaterialEditor';

class LessonEditor extends Component {
  state = {
    mode: 0,
    item: {},
    teachers: [],
    modules: [],
  }

  fetch(lesson) {
    client.getLesson(lesson.id)
      .then(resp => {
        this.setState({ item: resp.data.lesson })
      })
  }

  creating = () => {
    return typeof this.state.item.id === 'undefined'
  }

  clearData = lesson => {
    const lesson_copy = omit(lesson, [
      'materials',
    ])
    lesson_copy.teachers = lesson_copy.teachers && lesson_copy.teachers.map(i => ({id: i.id}))
    return lesson_copy
  }

  commit = lesson => {
    const {refresh, course} = this.props
    const {item: {teachers: itemTeachers}} = this.state
    lesson.number = Number(lesson.number)
    lesson.duration = Number(lesson.duration)
    lesson.free = lesson.free === 'true'
    if(lesson.module_id === 0) lesson.module_id = null

    if (this.creating()) {
      lesson.course_id = course.id;
      return client.postLesson(lesson)
        .then(() => refresh())
        .then(courseUpdated => {
          // now 'lesson' is without an id, we get all course from refresh() response
          const lessonUpdated = courseUpdated.lessons.find(item => item.number === lesson.number)
          // in course.lessons we don't have 'teachers' and 'materials'
          // that comes from getLesson response in fetch method
          this.fetch(lessonUpdated)
        })
    }

    const updated = Object.assign({}, lesson)
    updated.teachers = itemTeachers
    return client.putLesson(this.clearData(updated))
      .then(() => refresh())
      // update state with new complete data
      .then(() => this.fetch(lesson))
  }

  remove = lesson => {
    const {refresh} = this.props
    client.deleteLesson(lesson).then(() => refresh())
  }

  nav = mode => {
    this.setState({ mode: mode })
    if (mode === 0) {
      this.setState({ item: {} })
    }
  }

  addTeacher = (assoc) => {
    const lesson = cloneDeep(this.state.item);
    lesson.teachers = (lesson.teachers || []).concat([assoc]);
    return client.putLesson(this.clearData(lesson))
      .then(() => this.fetch(lesson));
  }

  deleteTeacher = (teacher) => {
    const lesson = cloneDeep(this.state.item);
    lesson.teachers = lesson.teachers.filter((t) => t.id !== teacher.id);
    return client.putLesson(this.clearData(lesson))
      .then(() => this.fetch(lesson));
  }

  render() {
    const {items} = this.props
    const {mode, item, teachers, modules} = this.state
    const fields = form(item)
    const moduleField = fields.find(f => f.name === 'module_id')
    moduleField.options = modules
    return (
      <>
      {mode === 0 && (
        <LessonSummaryTable
          items={items}
          row={lesson => lesson.title}
          config={{
            add: () => this.nav(1),
            edit: (lesson) => {
              this.fetch(lesson)
              this.nav(1)
            },
            delete: (lesson) => this.remove(lesson),
          }}
          modules={modules}
        />
      )}
      {mode === 1 && (
        <>
          <EntityForm
            fields={fields}
            success={this.commit}
            dismiss={() => this.nav(0)}
          />
          {!this.creating() && (
          <>
            <MaterialEditor
              lesson={item}
              refresh={() => this.fetch(item)}
              items={item.materials}
            />
            <AssociationEditor
              title="Insegnanti"
              all={teachers}
              current={item.teachers}
              add={this.addTeacher}
              remove={this.deleteTeacher}
            />
          </>
          )}
        </>
      )}
      </>
    )
  }

  componentDidMount(){
    const {academy_id, id} = this.props.course
    client.getTeachers(academy_id)
      .then(resp => {
        const teachers = resp.data.teachers.map((t) => {
          return {
            value: t.id,
            label: t.name,
          }
        })
        this.setState({teachers})
      })

    client.getModules(id)
      .then(resp => {
        const modules = resp.data.modules.map(m => ({
          value: m.id,
          label: m.title,
        }))
        modules.push({value: 0, label: ''})
        this.setState({modules})
      })
  }
}

export { LessonEditor }
