import React from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import CourseIcon from '@material-ui/icons/School';
import FacultyIcon from '@material-ui/icons/AccountBalance';
import TeacherIcon from '@material-ui/icons/RecordVoiceOver';
import CategoryIcon from '@material-ui/icons/Category';
import UserIcon from '@material-ui/icons/Person';
import LogoutIcon from '@material-ui/icons/ExitToApp';

import { getCurrentAcademy, getTitle } from '../../reducers/selectors';

import CustomAppBar from '../CustomAppBar/CustomAppBar';
import MainDrawer from '../MainDrawer/MainDrawer';

const menuItems = (id) => [
  {
    title: 'Corsi',
    icon: <CourseIcon/>,
    action: `/academies/${id}/courses`,
  },
  {
    title: 'Facoltà',
    icon: <FacultyIcon/>,
    action: `/academies/${id}/faculties`,
  },
  {
    title: 'Insegnanti',
    icon: <TeacherIcon/>,
    action: `/academies/${id}/teachers`,
  },
  {
    title: 'Categorie',
    icon: <CategoryIcon/>,
    action: `/academies/${id}/categories`,
  },
  {
    title: 'Utenti',
    icon: <UserIcon/>,
    action: '/users',
  },
];

const otherItems = [
  {
    title: 'Logout',
    icon: <LogoutIcon/>,
    action: '/logout',
  },
]

const items = (id) => [menuItems(id), otherItems]

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
  },
});

function PageTitle({title}){
  return (
    <p><strong>{title}</strong></p>
  )
}

function AcademyCommonBlock({classes, academy, pageTitle}) {
  return (
    <>
      <CustomAppBar
        title={<PageTitle title={pageTitle}/>}
        position="fixed"
        className={classes.appBar}
      />
      <MainDrawer items={items(academy.id)} />
    </>
  )
}

const mapState = (state) => {
  return {
    academy: getCurrentAcademy(state),
    pageTitle: getTitle(state),
  }
}

export default withStyles(styles)(connect(mapState)(AcademyCommonBlock))
