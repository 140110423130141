import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';
import { FormikTextField, FormikSelectField } from 'formik-material-fields';

import {defaultSchema, validationSchema, typeMapping} from './EntityForm';
import UploadDialog from '../UploadDialog/UploadDialog';

import style from './EntityForm.module.css';

class MaterialEntityForm extends Component {
  state = {
    upload: {},
    parents: {},
  }

  onSubmit = (res) => {
    Object.keys(this.state.upload).forEach((key) => {
      res[key] = this.state.upload[key]
    })

    Object.keys(this.state.parents).forEach((key) => {
      res[key] = this.state.parents[key]
    })

    this.props.success(res)
  }

  render() {
    const {fields, dismiss, testObj} = this.props;
    let vSchema = validationSchema(fields)
    if(testObj){
      vSchema = vSchema.test(testObj)
    }
    
    return (
      <Formik
        enableReinitialize={true}
        initialValues={defaultSchema(fields)}
        validationSchema={vSchema}
        onSubmit={this.onSubmit}
      >
        {({ isValid, setFieldValue }) => (
        <Form className={style.form}>
          {fields.map((field, index) => {
            const type = typeMapping(field.type);
            return (type === 'select') ? (
              <FormikSelectField
                key={index}
                name={field.name}
                label={field.label}
                options={field.options}
                fullWidth
              />
            ) : (type === 'hidden') ? (
              <input
                type="hidden"
                key={index}
                name={field.name}
                value={field.initial}
              />
            ) : (
              <FormikTextField
                key={index}
                name={field.name}
                label={field.label}
                margin="dense"
                multiline={type === 'textarea'}
                fullWidth
              />
            )}
          )}
          <div style={{display: 'flex'}}>
            <div>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={!isValid && isEmpty(this.state.upload) && isEmpty(this.state.parents)}
              >
                Salva
              </Button>
              {dismiss && (
              <Button size="large" onClick={dismiss}>
                Indetro
              </Button>
              
              )}
            </div>
            <div>
            <UploadDialog
                key={41}
                name={"generic-name"}
                type={"generic"}
                button="Upload Doc"
                success={(name, url) => {
                  setFieldValue('url', url)
                  setFieldValue('kind', 1)
                }}
              />
              <UploadDialog
                key={42}
                name={"pdf-name"}
                type={"pdf"}
                button="Upload pdf/epub"
                success={(name, url) => {
                  setFieldValue('url', url)
                  setFieldValue('kind', 1)
                }}
              />
              <UploadDialog
                key={43}
                name={"mp3-name"}
                type={"mp3"}
                button="Upload mp3"
                success={(name, url) => {
                  setFieldValue('url', url)
                  setFieldValue('kind', 5)
                }}
              />
            </div>
          </div>     
        </Form>
        )}
      </Formik>
    )
  }
}

export default MaterialEntityForm
