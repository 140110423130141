import * as Yup from 'yup';

const form = (material) => [
  {
    name: 'id',
    type: 'hidden',
    initial: material.id || 0,
  },
  {
    name: 'lesson_id',
    type: 'hidden',
    initial: material.lesson_id || '',
  },
  {
    name: 'name',
    type: 'string',
    label: 'Nome',
    validation: Yup.string().required(),
    initial: material.name || '',
  },
  {
    name: 'url',
    type: 'string',
    label: 'URL',
    validation: Yup.string().url().required(),
    initial: material.url || '',
  },
  {
    name: 'kind',
    type: 'select',
    label: 'Tipologia',
    validation: Yup.number().required(),
    initial: material.kind || 1,
    options: [
      {
        label: 'Documento',
        value: 1,
      },
      {
        label: 'Articolo',
        value: 2,
      },
      {
        label: 'Immagine',
        value: 3,
      },
      {
        label: 'Video',
        value: 4,
      },
      {
        label: 'Audio',
        value: 5,
      }
    ],
  },
];

export default form
