export const API_LOADING = 'API_LOADING';
export const API_REQUEST = 'API_REQUEST';
export const API_SUCCESS = 'API_SUCCESS';
export const API_FAILURE = 'API_FAILURE';
export const API_FAILURE_CLEAR = 'API_FAILURE_CLEAR';
export const API_REQUEST_IF_NOT_IN_STATE = 'API_REQUEST_IF_NOT_IN_STATE';
export const SET_SUCCESS_MODAL = 'SET_SUCCESS_MODAL';
export const DELETE_USER = 'DELETE_USER';
export const AUTH_PROGRESS = 'AUTH_PROGRESS';
export const DELETE_CURRENT_ACADEMY = 'DELETE_CURRENT_ACADEMY';
export const SET_TITLE = 'SET_TITLE';
