import app from 'firebase/app';
import 'firebase/auth';
import { store } from '../index';
import { setAuthProgress, getUser } from '../actions';

import { clientInstance as client } from '../api/upcademy';

let env = process.env.NODE_ENV
if (env === 'production' && process.env.REACT_APP_ENV === 'staging'){
  env = 'staging'
}

const config = (env === 'production') ? {
  apiKey: "AIzaSyDsC_alSuDSeUvCBaU4hHqmM5s1LV981x8",
  authDomain: "up-prod-facf2.firebaseapp.com",
  databaseURL: "https://up-prod-facf2.firebaseio.com",
  projectId: "up-prod-facf2",
  storageBucket: "up-prod-facf2.appspot.com",
  messagingSenderId: "216865246835",
  appId: "1:216865246835:web:7a553640985772d7"
} : {
  apiKey: "AIzaSyDY228gAffMX9mT_ckinia4OvPC6orgWn8",
  authDomain: "up-test-9f129.firebaseapp.com",
  databaseURL: "https://up-test-9f129.firebaseio.com",
  projectId: "up-test-9f129",
  storageBucket: "up-test-9f129.appspot.com",
  messagingSenderId: "824635371953"
};

app.initializeApp(config)

const auth = app.auth()
const googleProvider = new app.auth.GoogleAuthProvider()

const doSocialSignIn = (provider) => {
  return auth.signInWithPopup(provider).then(function(result) {
    return result
  }).catch(function(error) {
    // Handle Errors here.
    // var errorCode = error.code;
    // var errorMessage = error.message;
    // The email of the user's account used.
    // var email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    // var credential = error.credential;
    console.error(error)
  });
}

app.auth().onAuthStateChanged(function(user) {
  if (!user) {
    store.dispatch(setAuthProgress({firebaseReady: true}))
    return
  }

  user.getIdToken()
    .then(token => {
      client.startSession(token)
        .then(({data: {user_id}}) => {
          store.dispatch(getUser(user_id))
          store.dispatch(setAuthProgress({firebaseReady: true, user_id: user_id}))
        })
    })
})
    
const doGoogleSignIn = () => doSocialSignIn(googleProvider)

const doSignOut = () => auth.signOut()

const getFirebaseUser = () => auth.currentUser

export default app
export { doGoogleSignIn, doSignOut, getFirebaseUser } 
