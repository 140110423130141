import React, { Component } from 'react';

import { clientInstance as client } from '../../api/upcademy.js'
import PillEntityForm from '../EntityForm/PillEntityForm'
import SummaryTable, {Mode as SummaryTableMode} from '../SummaryTable/SummaryTable'
import form from '../../forms/pill'

const initialState = {
  mode: 0,
  item: {},
}

class PillEditor extends Component {
  state = initialState

  commit = (pill) => {
    const {pillSerie, refresh} = this.props

    pill.duration = Number(pill.duration)

    if (pill.id === 0) {
      pill.pill_serie_id = pillSerie.id;
      return client.postPill(pill)
        .then(() => this.setState(initialState))
        .then(() => refresh())
    }

    return client.putPill(pill)
      .then(() => this.setState(initialState))
      .then(() => refresh())
  }

  remove = (pill) => {
    const {refresh} = this.props
    client.deletePill(pill)
      .then(() => refresh())
  }

  nav = (mode, item) => {
    this.setState({
      mode: mode,
      item: item,
    })
  }

  render() {
    const {pillSerie, items} = this.props
    const {mode} = this.state
    return (
      <>
      {mode === 0 && (
        <SummaryTable
          title={`[${pillSerie.title}] Pills`}
          items={items}
          row={(pill) => pill.title}
          config={{
            add: () => this.nav(1, {}),
            edit: (pill) => this.nav(1, pill),
            delete: (pill) => this.remove(pill),
          }}
          mode={SummaryTableMode.dark}
        />
      )}
      {mode === 1 && (
        <PillEntityForm
          fields={form(this.state.item)}
          success={this.commit}
          dismiss={() => this.nav(0, {})}
        />
      )}
      </>
    )
  }
}

export default PillEditor
