import moment from 'moment';
import * as actions from './actionsTypes';
import * as entities from '../api/entitiesConstant';


export const setApiLoading = ({entity, loading}) => ({
  type: actions.API_LOADING,
  payload: {scope: entity, loading: loading}
})

export const setApiSuccess = ({entity, data}) => ({
  type: actions.API_SUCCESS,
  payload: {scope: entity, data}
})

export const setApiError = ({entity, error}) => ({
  type: actions.API_FAILURE,
  payload: {entity, error, createdAt: moment().toISOString()}
})

export const clearApiError = () => ({
  type: actions.API_FAILURE_CLEAR,
})

export const getAcademy = id => ({
  type: actions.API_REQUEST,
  entity: entities.ACADEMIES,
  params: {id}
})

export const getOrFetchAcademy = id => ({
  type: actions.API_REQUEST_IF_NOT_IN_STATE,
  entity: entities.ACADEMIES,
  params: {id}
})

export const setSuccessModal = value => ({
  type: actions.SET_SUCCESS_MODAL,
  value: value
})

export const setAuthProgress = ({firebaseReady, user_id=null}) => ({
  type: actions.AUTH_PROGRESS,
  firebaseReady: firebaseReady,
  user_id: user_id,
})

export const getUser = id => ({
  type: actions.API_REQUEST,
  entity: entities.USER,
  params: {id}
})

export const deleteUser = () => ({
  type: actions.DELETE_USER
})

export const deleteCurrentAcademy = () => ({
  type: actions.DELETE_CURRENT_ACADEMY
})

export const setTitle = value => ({
  type: actions.SET_TITLE,
  value: value
})