import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import SummaryTable from '../components/SummaryTable/SummaryTable'

class EntityList extends Component {

  render() {
    const {match: {params: {id}}, history} = this.props
    const {title, items, add, edit} = this.props

    return (
      <SummaryTable
        title={title}
        items={items}
        config={{
          add: () => history.push(add(id)),
          edit: (item) => history.push(edit(item)),
        }}
      />
    )
  }
}

export default withRouter(EntityList)
