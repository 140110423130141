import { combineReducers } from 'redux';
import * as actions from '../actions/actionsTypes';
import * as entitiesConst from '../api/entitiesConstant';

const currentAcademy = (state={}, action) => {
  if (action.type === actions.API_SUCCESS && action.payload.scope === entitiesConst.ACADEMIES) {
    return action.payload.data.academy
  }else if (action.type === actions.DELETE_CURRENT_ACADEMY) {
    return {}
  }else{
    return state
  }
}

const user = (state={}, action) => {
  if (action.type === actions.API_SUCCESS && action.payload.scope === entitiesConst.USER) {
    return action.payload.data.user
  }else if (action.type === actions.DELETE_USER){
    return {}
  }else{
    return state
  }
}

const entities = combineReducers({
  currentAcademy,
  user,
})

export default entities
