import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/it';

import { clientInstance as client } from '../../api/upcademy.js'
import EntityForm from '../EntityForm/EntityForm'
import SummaryTable from '../SummaryTable/SummaryTable'
import form from '../../forms/article'

class NewsEditor extends Component {
  state = {
    mode: 0,
    item: {},
  }

  commit = (article) => {
    const {faculty, refresh} = this.props
    if (article.id === 0) {
      article.faculty_id = faculty.id;
      article.published_at = moment().locale('it').toISOString()
      return client.postArticle(article).then(() => refresh())
    }

    return client.putArticle(article).then(() => refresh())
  }

  remove = (article) => {
    const {refresh} = this.props
    client.deleteArticle(article).then(() => refresh())
  }

  nav = (mode, item) => {
    this.setState({
      mode: mode,
      item: item,
    })
  }

  testFunction = item => {
    if(item.body || item.audio) return true
    return false
  }

  testObj = {
    name: 'Check body-or-audio',
    message: 'Both audio and body are empty',
    test: this.testFunction
  }

  render() {
    const {items} = this.props
    const {mode, item} = this.state
    return (
      <>
      {mode === 0 && (
        <SummaryTable
          title="News"
          items={items}
          row={article => article.title}
          config={{
            add: () => this.nav(1, {}),
            edit: (article) => this.nav(1, article),
            delete: (article) => this.remove(article),
          }}
        />
      )}
      {mode === 1 && (
        <EntityForm
          testObj={this.testObj}
          fields={form(item)}
          success={this.commit}
          dismiss={() => this.nav(0, {})}
        />
      )}
      </>
    )
  }
}

export default NewsEditor
