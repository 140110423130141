import React from 'react';
import { withStyles, Typography } from '@material-ui/core';

const styles = theme => ({
  descriptionUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  descriptionContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
})

function TopDescription ({classes, title, description}) {
  return (
    <div className={classes.descriptionUnit}>
      <div className={classes.descriptionContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
          {description}
        </Typography>
      </div>
    </div>
  )
}

export default withStyles(styles)(TopDescription)
