import * as Yup from 'yup';
import field from './status'

const form = (pillSerie) => [
  {
    name: 'id',
    type: 'hidden',
    initial: pillSerie.id || 0,
  },
  {
    name: 'faculty_id',
    type: 'hidden',
    initial: pillSerie.faculty_id || '',
  },
  {
    name: 'title',
    type: 'string',
    label: 'Titolo',
    validation: Yup.string().required(),
    initial: pillSerie.title || '',
  },
  {
    name: 'description',
    type: 'text',
    label: 'Descrizione',
    validation: Yup.string().required(),
    initial: pillSerie.description || '',
  },
  {
    name: 'cover',
    type: 'file',
    label: 'Immagine di copertina (formato 3:2 es. 300x200)',
    initial: pillSerie.cover,
  },
  field(pillSerie),
];

export default form
