import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../actions';

import EntityForm from '../EntityForm/EntityForm'
import SummaryTable from '../SummaryTable/SummaryTable'
import { clientInstance as client } from '../../api/upcademy.js'
import form from '../../forms/teacher'

class TeacherEditor extends Component {
  state = {
    mode: 0,
    item: {},
    items: [],
  }

  fetch = () => {
    client.getTeachers(this.props.match.params.id)
      .then((resp) => this.setState({ items: resp.data.teachers }))
  }

  commit = (teacher) => {
    if (teacher.id === 0) {
      teacher.academy_id = this.props.match.params.id;
      return client.postTeacher(teacher).then(() => this.fetch())
    }

    return client.putTeacher(teacher).then(() => this.fetch())
  }

  nav = (mode, item) => {
    this.setState({
      mode: mode,
      item: item,
    })
  }

  render() {
    const {mode} = this.state
    return (
      <>
      {mode === 0 && (
        <SummaryTable
          title="Insegnanti"
          items={this.state.items}
          config={{
            add: () => this.nav(1, {}),
            edit: (teacher) => this.nav(1, teacher),
          }}
        />
      )}
      {mode === 1 && (
        <>
          <EntityForm
            fields={form(this.state.item)}
            success={this.commit}
            dismiss={() => this.nav(0, {})}
          />
        </>
      )}
      </>
    )
  }

  componentDidMount() {
    const {match: {params: {id}}, setTitle} = this.props
    this.props.getAcademy(id)
    this.fetch()
    setTitle('')
  }
}

const mapDispatch = (dispatch) => {
  return {
    getAcademy: (id) => dispatch(actions.getOrFetchAcademy(id)),
    setTitle: title => dispatch(actions.setTitle(title)),
  }
}

export default withRouter(connect(null, mapDispatch)(TeacherEditor))
