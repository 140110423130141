import * as Yup from 'yup';

const form = (category) => [
  {
    name: 'id',
    type: 'hidden',
    initial: category.id || 0,
  },
  {
    name: 'academy_id',
    type: 'hidden',
    initial: category.academy_id || '',
  },
  {
    name: 'name',
    type: 'string',
    label: 'Nome',
    validation: Yup.string().required(),
    initial: category.name,
  },
];

export default form
