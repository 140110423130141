import React, { Component } from 'react';

import { clientInstance as client } from '../../api/upcademy.js'
import EntityForm from '../EntityForm/EntityForm'
import SummaryTable from '../SummaryTable/SummaryTable'
import form from '../../forms/topic'

const initialState = {
  mode: 0,
  item: {},
}

class TopicEditor extends Component {
  state = initialState

  commit = topic => {
    const {course, refresh} = this.props
    if (topic.id === 0) {
      topic.course_id = course.id;
      return client.postTopic(topic)
        .then(() => this.setState(initialState))
        .then(() => refresh())
    }

    return client.putTopic(topic)
      .then(() => this.setState(initialState))
      .then(() => refresh())
  }

  remove = (topic) => {
    const {refresh} = this.props
    client.deleteTopic(topic)
      .then(() => refresh())
  }

  nav = (mode, item) => {
    this.setState({
      mode: mode,
      item: item,
    })
  }

  render() {
    const {items} = this.props
    const {mode} = this.state
    return (
      <>
      {mode === 0 && (
        <SummaryTable
          items={items}
          config={{
            add: () => this.nav(1, {}),
            edit: (topic) => this.nav(1, topic),
            delete: (topic) => this.remove(topic),
          }}
        />
      )}
      {mode === 1 && (
        <EntityForm
          fields={form(this.state.item)}
          success={this.commit}
          dismiss={() => this.nav(0, {})}
        />
      )}
      </>
    )
  }
}

export { TopicEditor }
