import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import React, { Component } from 'react';
import { Button, Link, Grid } from '@material-ui/core';
import { DashboardModal } from '@uppy/react';

import Uploader, {PdfUploader, Mp3Uploader, GenericDocUploader} from '../../api/uploader';

import style from './UploadDialog.module.css';

class UploadDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      value: props.initial,
    }

    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)

    if (props.type === 'pdf'){
      this.uploader = new PdfUploader(props.name, this.handleSuccess)  
    } else if (props.type === 'mp3'){
      this.uploader = new Mp3Uploader(props.name, this.handleSuccess)  
    } else if (props.type === 'generic'){
      this.uploader = new GenericDocUploader(props.name, this.handleSuccess)  
    }else{
      this.uploader = new Uploader(props.name, this.handleSuccess)
    }
    
  }

  handleSuccess(result) {
    this.setState({
      value: result.uploadURL,
    })
    this.props.success(result.meta.fieldName, result.uploadURL)
  }

  handleOpen() {
    this.setState({
      modalOpen: true,
    })
  }

  handleClose() {
    this.setState({
      modalOpen: false,
    })
  }

  render() {
    const {title, button} = this.props
    return (
      <>
        <Grid container className={style.root}>
          <Grid item className={style.titleWrap}>
            <p>{title}</p>
          </Grid>
          <Grid item className={style.viewUpload}>
            {this.state.value && (
            <Link
              href={this.state.value}
              target="_blank"
            >
              View
            </Link>
            )}
            <Button
              variant="contained"
              onClick={this.handleOpen}
              // style={{backgroundColor: '#f0f0f0'}}
              className={style.uploadButton}
            >
              {button}
            </Button>
          </Grid>
        </Grid>
        <DashboardModal
          uppy={this.uploader.uppy}
          open={this.state.modalOpen}
          onRequestClose={this.handleClose}
          closeModalOnClickOutside
        />
      </>
    )
  }

  componentDidUpdate(prevProps){
    if (prevProps.initial !== this.props.initial){
      this.setState({value: this.props.initial})
    }
  }
}

UploadDialog.defaultProps = {
  button: "Upload",
}

export default UploadDialog
