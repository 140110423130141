import React from 'react';
import { Modal, withStyles } from '@material-ui/core';
import DoneOutline from '@material-ui/icons/DoneOutline';

import { connect } from 'react-redux';

import { setSuccessModal } from '../../actions';
import { styles, getModalStyle} from './utility';

function ApiSuccessModal({closeModal, classes, successState}){
  const handleClose = () => {
    closeModal()
  }

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={successState}
      onClose={handleClose}>
      <div style={getModalStyle()} className={classes.paper}>
        <div style={{textAlign: 'center'}}>
          <DoneOutline/>
        </div>
      </div>
    </Modal>
  )
}

const mapState = (state) => {
  return {
    successState: state.successModal
  }
}

const mapDispatch = (dispatch) => {
  return {
    closeModal: () => dispatch(setSuccessModal(false))
  }
}

export default withStyles(styles)(connect(mapState, mapDispatch)(ApiSuccessModal))