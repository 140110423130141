import axios from 'axios';
import * as actions from '../actions';
import { store } from '../index';
import { getStructuredError } from './utility';

let env = process.env.NODE_ENV

if (env === 'production' && process.env.REACT_APP_ENV === 'staging'){
  env = 'staging'
}

const dev = 'http://localhost:8000'
// const staging = 'https://dev.api.upacademy.eu'
const staging = 'https://stagingapi.upacademy.eu'
const prod = 'https://api.upacademy.eu'

const path = '/api/v1/'

const host = (env === 'production') ? prod : (env === 'staging') ? staging : dev

const baseURL = `${host}${path}`

const apiPromiseHandler = (promise, showModal=false) => {
  return promise
    .then(response => {
      if (showModal && response.status >= 200 && response.status < 300){
        store.dispatch(actions.setSuccessModal(true))
      }
      return response
    })
    .catch(err => {
      store.dispatch(actions.setApiError({entity: "", error: getStructuredError(err)}))
      throw err
    })
}

class Client {

  constructor(baseUrl){
    this.instance = axios.create({baseURL: baseUrl})
    this.auth = {}
  }

  setAuthFromResponse = (response) => {
    const {access_token, refresh_token} = response.data
    this.auth = {access_token, refresh_token}
    return response
  }

  startSession = firebase_token => {
    return this.getSessionData(firebase_token)
      .then(response => this.setAuthFromResponse(response))
  }

  refreshSession = () => {
    return this.refreshSessionData(this.auth.refresh_token)
      .then(response => this.setAuthFromResponse(response))
  }

  fillRequest = (config) => {
    const {access_token} = this.auth
    const headers = access_token ? {'Authorization': `Bearer ${access_token}`} : {}
    const requestConfig = {
      url: config.endpoint,
      headers: headers,
      params: config.params}
    if (config.method === 'GET'){
      requestConfig.method = 'get';
    } else if (config.method === 'POST'){
      requestConfig.method = 'post';
      requestConfig.data = config.data;
    } else if (config.method === 'PUT'){
      requestConfig.method = 'put';
      requestConfig.data = config.data;
    } else if (config.method === 'DELETE'){
      requestConfig.method = 'delete';
      requestConfig.data = config.data;
    }
    return requestConfig
  }

  makeRequest = config => this.instance(config)

  request = (config) => {
    const filledRequest = this.fillRequest(config)
    return this.makeRequest(filledRequest)
      .catch(err => {
        const {response: {status}} = err
        if (status === 410){
          return this.refreshSession()
            .then(() => this.request(config))
        }else{
          throw err;
        }
      })
  }


  getAcademies () {
    const config = {
      endpoint: '/academies',
      method: 'GET',
    }
    return this.request(config)
  }

  getAcademy (id) {
    const config = {
      endpoint: `/academies/${id}`,
      method: 'GET',
    }
    return this.request(config)
  }

  getFaculties (academy) {
    return this.request({
      method: 'GET',
      endpoint: `/academies/${academy}/faculties`,
    })
  }

  postFaculty (faculty) {
    return this.request({
      method: 'POST',
      endpoint: `/academies/${faculty.academy_id}/faculties`,
      data: {
        faculty: faculty,
      },
    })
  }

  putFaculty (faculty) {
    return this.request({
      method: 'PUT',
      endpoint: `/faculties/${faculty.id}`,
      data: {
        faculty: faculty,
      },
    })
  }

  getTeachers (academy) {
    return this.request({
      method: 'POST',
      endpoint: `/academies/${academy}/teacher_searches`,
      data: {
        teacher: {},
      },
    })
  }

  postTeacher (teacher) {
    return this.request({
      method: 'POST',
      endpoint: `/academies/${teacher.academy_id}/teachers`,
      data: {
        teacher: teacher,
      },
    })
  }

  putTeacher (teacher) {
    return this.request({
      method: 'PUT',
      endpoint: `/teachers/${teacher.id}`,
      data: {
        teacher: teacher,
      },
    })
  }

  getCategories (academy) {
    return this.request({
      method: 'POST',
      endpoint: `/academies/${academy}/category_searches`,
      data: {
        category: {},
      },
    })
  }

  postCategory (category) {
    return this.request({
      method: 'POST',
      endpoint: `/academies/${category.academy_id}/categories`,
      data: {
        category: category,
      },
    })
  }

  putCategory (category) {
    return this.request({
      method: 'PUT',
      endpoint: `/categories/${category.id}`,
      data: {
        category: category,
      },
    })
  }

  deleteCategory (category) {
    return this.request({
      method: 'DELETE',
      endpoint: `/categories/${category.id}`,
    })
  }

  getCourses (academy) {
    return this.request({
      method: 'POST',
      endpoint: `/academies/${academy}/course_searches`,
      data: {
        course: {},
      },
    })
  }

  getCourse (id) {
    return this.request({
      method: 'GET',
      endpoint: `/courses/${id}`,
    })
  }

  putCourse (course) {
    return this.request({
      method: 'PUT',
      endpoint: `/courses/${course.id}`,
      data: {
        course: course,
      },
    })
  }

  postCourse (course) {
    return this.request({
      method: 'POST',
      endpoint: `/academies/${course.academy_id}/courses`,
      data: {
        course: course,
      },
    })
  }

  postPill (pill) {
    return this.request({
      method: 'POST',
      endpoint: `/pill_series/${pill.pill_serie_id}/pills`,
      data: {
        pill: pill,
      },
    })
  }

  putPill (pill) {
    return this.request({
      method: 'PUT',
      endpoint: `/pills/${pill.id}`,
      data: {
        pill: pill,
      },
    })
  }

  deletePill (pill) {
    return this.request({
      method: 'DELETE',
      endpoint: `/pills/${pill.id}`,
    })
  }

  postPillSerie (pillSerie) {
    return this.request({
      method: 'POST',
      endpoint: `/faculties/${pillSerie.faculty_id}/pill_series`,
      data: {
        pill_serie: pillSerie,
      },
    })
  }

  putPillSerie (pillSerie) {
    return this.request({
      method: 'PUT',
      endpoint: `/pill_series/${pillSerie.id}`,
      data: {
        pill_serie: pillSerie,
      },
    })
  }

  deletePillSerie (pillSerie) {
    return this.request({
      method: 'DELETE',
      endpoint: `/pill_series/${pillSerie.id}`,
    })
  }

  postConcept (concept) {
    return this.request({
      method: 'POST',
      endpoint: `/faculties/${concept.faculty_id}/concepts`,
      data: {
        concept: concept,
      },
    })
  }

  putConcept (concept) {
    return this.request({
      method: 'PUT',
      endpoint: `/concepts/${concept.id}`,
      data: {
        concept: concept,
      },
    })
  }

  deleteConcept (concept) {
    return this.request({
      method: 'DELETE',
      endpoint: `/concepts/${concept.id}`,
    })
  }

  getTopic (id) {
    return this.request({
      method: 'GET',
      endpoint: `/topics/${id}`,
    })
  }

  postTopic (topic) {
    return this.request({
      method: 'POST',
      endpoint: `/courses/${topic.course_id}/topics`,
      data: {
        topic: topic,
      },
    })
  }

  putTopic (topic) {
    return this.request({
      method: 'PUT',
      endpoint: `/topics/${topic.id}`,
      data: {
        topic: topic,
      },
    })
  }

  deleteTopic (topic) {
    return this.request({
      method: 'DELETE',
      endpoint: `/topics/${topic.id}`,
    })
  }

  getLesson (id) {
    return this.request({
      method: 'GET',
      endpoint: `/lessons/${id}`,
    })
  }

  postLesson (lesson) {
    return this.request({
      method: 'POST',
      endpoint: `/courses/${lesson.course_id}/lessons`,
      data: {
        lesson: lesson,
      },
    })
  }

  putLesson (lesson) {
    return this.request({
      method: 'PUT',
      endpoint: `/lessons/${lesson.id}`,
      data: {
        lesson: lesson,
      },
    })
  }

  deleteLesson (lesson) {
    return this.request({
      method: 'DELETE',
      endpoint: `/lessons/${lesson.id}`,
    })
  }

  getMaterial (id) {
    return this.request({
      method: 'GET',
      endpoint: `/materials/${id}`,
    })
  }

  postMaterial (material) {
    return this.request({
      method: 'POST',
      endpoint: `/lessons/${material.lesson_id}/materials`,
      data: {
        material: material,
      },
    })
  }

  putMaterial (material) {
    return this.request({
      method: 'PUT',
      endpoint: `/materials/${material.id}`,
      data: {
        material: material,
      },
    })
  }

  deleteMaterial (material) {
    return this.request({
      method: 'DELETE',
      endpoint: `/materials/${material.id}`,
    })
  }

  getSessionData (firebase_token) {
    return this.request({
      method: 'POST',
      endpoint: `sessions`,
      data: {
        firebase_jwt: firebase_token
      }
    })
  }

  refreshSessionData (refresh_token) {
    return this.request({
      method: 'PUT',
      endpoint: 'sessions/0',
      data: {
        refresh_token: refresh_token,
      }
    })
  }

  getUser (id) {
    return this.request({
      method: 'GET',
      endpoint: `users/${id}`,
    })
  }

  postObject (file) {
    return this.request({
      method: 'POST',
      endpoint: '/academies/up/objects',
      data: {
        object: {
          name: file.name,
          type: file.type,
          folder: file.folder,
          real_name: file.real_name,
        },
      },
    })
  }

  getArticles (faculty) {
    return this.request({
      method: 'POST',
      endpoint: `/faculties/${faculty.id}/article_searches`,
      data: {
        article: {}
      }
    })
  }

  postArticle (article) {
    return this.request({
      method: 'POST',
      endpoint: `faculties/${article.faculty_id}/articles`,
      data: {article},
    })
  }

  putArticle (article) {
    return this.request({
      method: 'PUT',
      endpoint: `/articles/${article.id}`,
      data: {article},
    })
  }

  deleteArticle (article) {
    return this.request({
      method: 'DELETE',
      endpoint: `/articles/${article.id}`,
    })
  }

  getModule (cModule) {
    return this.request({
      method: 'GET',
      endpoint: `/modules/${cModule.id}`,
    })
  }

  getModules (course_id) {
    return this.request({
      method: 'POST',
      endpoint: `courses/${course_id}/module_searches`,
      data: {
        module: {}
      }
    })
  }

  postModule (cModule) {
    return this.request({
      method: 'POST',
      endpoint: `courses/${cModule.course_id}/modules`,
      data: {module: cModule},
    })
  }

  putModule (cModule) {
    return this.request({
      method: 'PUT',
      endpoint: `modules/${cModule.id}`,
      data: {module: cModule},
    })
  }

  deleteModule (cModule) {
    return this.request({
      method: 'DELETE',
      endpoint: `/modules/${cModule.id}`,
    })
  }

  getLiveEvent (event) {
    return this.request({
      method: 'GET',
      endpoint: `live_events/${event.id}`,
    })
  }

  getLiveEvents (faculty_id, status) {
    const liveEvent = {}
    if (status) liveEvent.status = status
    return this.request({
      method: 'POST',
      endpoint: `faculties/${faculty_id}/live_event_searches`,
      data: {live_event: liveEvent},
    })
  }

  postLiveEvent (event) {
    return this.request({
      method: 'POST',
      endpoint: `faculties/${event.faculty_id}/live_events`,
      data: {live_event: event}
    })
  }

  putLiveEvent (event) {
    return this.request({
      method: 'PUT',
      endpoint: `live_events/${event.id}`,
      data: {live_event: event}
    })
  }

  deleteLiveEvent (event) {
    return this.request({
      method: 'DELETE',
      endpoint: `live_events/${event.id}`
    })
  }

  getVimeoInfo (id){
    return this.request({
      method: 'GET',
      endpoint: `/vimeo/${id}`
    })
  }

}

class ManagedClient extends Client {

  getAcademy (id) {
    return apiPromiseHandler(super.getAcademy(id))
  }

  getAcademies () {
    return apiPromiseHandler(super.getAcademies())
  }

  getFaculties (academy) {
    return apiPromiseHandler(super.getFaculties(academy))
  }

  postFaculty (teacher) {
    return apiPromiseHandler(super.postFaculty(teacher), true)
  }

  putFaculty (teacher) {
    return apiPromiseHandler(super.putFaculty(teacher), true)
  }

  getTeachers (academy) {
    return apiPromiseHandler(super.getTeachers(academy))
  }

  postTeacher (teacher) {
    return apiPromiseHandler(super.postTeacher(teacher), true)
  }

  putTeacher (teacher) {
    return apiPromiseHandler(super.putTeacher(teacher), true)
  }

  getCategories (academy) {
    return apiPromiseHandler(super.getCategories(academy))
  }

  postCategory (category) {
    return apiPromiseHandler(super.postCategory(category), true)
  }

  putCategory (category) {
    return apiPromiseHandler(super.putCategory(category), true)
  }

  deleteCategory (category) {
    return apiPromiseHandler(super.deleteCategory(category))
  }

  getCourses (academy) {
    return apiPromiseHandler(super.getCourses(academy))
  }

  getCourse (id) {
    return apiPromiseHandler(super.getCourse(id))
  }

  putCourse (course) {
    return apiPromiseHandler(super.putCourse(course), true)
  }

  postCourse (course) {
    return apiPromiseHandler(super.postCourse(course), true)
  }

  getTopic (id) {
    return apiPromiseHandler(super.getTopic(id))
  }

  postTopic (topic) {
    return apiPromiseHandler(super.postTopic(topic), true)
  }

  putTopic (topic) {
    return apiPromiseHandler(super.putTopic(topic), true)
  }

  deleteTopic (topic) {
    return apiPromiseHandler(super.deleteTopic(topic), true)
  }

  getLesson (id) {
    return apiPromiseHandler(super.getLesson(id))
  }

  postLesson (lesson) {
    return apiPromiseHandler(super.postLesson(lesson), true)
  }

  putLesson (lesson) {
    return apiPromiseHandler(super.putLesson(lesson), true)
  }

  deleteLesson (lesson) {
    return apiPromiseHandler(super.deleteLesson(lesson), true)
  }

  getMaterial (id) {
    return apiPromiseHandler(super.getMaterial(id))
  }

  postMaterial (material) {
    return apiPromiseHandler(super.postMaterial(material), true)
  }

  putMaterial (material) {
    return apiPromiseHandler(super.putMaterial(material), true)
  }

  deleteMaterial (material) {
    return apiPromiseHandler(super.deleteMaterial(material), true)
  }

  postPill (pill) {
    return apiPromiseHandler(super.postPill(pill), true)
  }

  putPill (pill) {
    return apiPromiseHandler(super.putPill(pill), true)
  }

  deletePill (pill) {
    return apiPromiseHandler(super.deletePill(pill), true)
  }

  postPillSerie (pillSerie) {
    return apiPromiseHandler(super.postPillSerie(pillSerie), true)
  }

  putPillSerie (pillSerie) {
    return apiPromiseHandler(super.putPillSerie(pillSerie), true)
  }

  deletePillSerie (pillSerie) {
    return apiPromiseHandler(super.deletePillSerie(pillSerie), true)
  }

  postObject (file) {
    return apiPromiseHandler(super.postObject(file));
  }

  postConcept (concept) {
    return apiPromiseHandler(super.postConcept(concept), true)
  }

  putConcept (concept) {
    return apiPromiseHandler(super.putConcept(concept), true)
  }

  deleteConcept (concept) {
    return apiPromiseHandler(super.deleteConcept(concept), true)
  }

  getArticles (article) {
    return apiPromiseHandler(super.getArticles(article))
  }

  postArticle (article) {
    return apiPromiseHandler(super.postArticle(article), true)
  }

  putArticle (article) {
    return apiPromiseHandler(super.putArticle(article), true)
  }

  deleteArticle (article) {
    return apiPromiseHandler(super.deleteArticle(article), true)
  }

  getModule (cModule) {
    return apiPromiseHandler(super.getModule(cModule))
  }

  getModules (course_id) {
    return apiPromiseHandler(super.getModules(course_id))
  }

  postModule (cModule) {
    return apiPromiseHandler(super.postModule(cModule), true)
  }

  putModule (cModule) {
    return apiPromiseHandler(super.putModule(cModule), true)
  }

  deleteModule (cModule) {
    return apiPromiseHandler(super.deleteModule(cModule), true)
  }

  getLiveEvent (event) {
    return apiPromiseHandler(super.getLiveEvent(event))
  }

  getLiveEvents (faculty_id, status) {
    return apiPromiseHandler(super.getLiveEvents(faculty_id, status))
  }

  postLiveEvent (event) {
    return apiPromiseHandler(super.postLiveEvent(event), true)
  }

  putLiveEvent (event) {
    return apiPromiseHandler(super.putLiveEvent(event), true)
  }

  deleteLiveEvent (event) {
    return apiPromiseHandler(super.deleteLiveEvent(event), true)
  }

  getVimeoInfo (id) {
    return apiPromiseHandler(super.getVimeoInfo(id))
  }
}

export const clientInstance = new ManagedClient(baseURL)
